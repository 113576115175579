import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from 'app/auth/models/user';
import { Jr, Matching_JR, ResumeParser, Sf4cCandidate, TalentPool } from '../../models/resume.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResumeParserService } from '../../services/resume-parser.service';

import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { AuthenticationService } from 'app/auth/service';
import { TalentPoolService } from 'app/main/talent-pool/services/talent-pool.service';
import { CoreConfigService } from '@core/services/config.service';
import { Subscription, interval, concat } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { range_conf, status_color } from 'app/main/talent-pool/models/resume.model';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CV_task } from 'app/main/talent-pool/models/job.model';
@Component({
  selector: 'app-detail-resume',
  templateUrl: './detail-resume.component.html',
  styleUrls: ['./detail-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailResumeComponent implements OnInit {
  public user!: User
  public isAuthenticated = false
  public resume_parser: ResumeParser = new ResumeParser()
  public errorMessagesParseResume!: any
  public errorMessagesApproveResume!: any
  public errorMessagesCensorResume!: any
  public errorMessagesDeCensorResume!: any
  public tag_status = false
  public tag_detail = false
  public pdf_content!: any
  public url!: any


  dateMaxDate = new Date(1960, 0, 1);
  dateMinDate = new Date(2010, 0, 1);
  public status_color = status_color
  public range_conf = range_conf
  //Tab 
  public tab_sf4c = false
  //sf4c candidate
  public sf4cCandidate: Sf4cCandidate = new Sf4cCandidate()
  public errorMessagesGetSf4cCandidate!: any
  public tag_sf4c_candidate = false
  public dateOfBirth!: any
  public resultsEducation: any[] = []
  public resultsExperience: any[] = []
  public pdf_content_sf4c!: any
  public url_sf4c!: any
  public errorMessagesResultConnectSf4cs!: any
  public tag_resultConnectSf4c_exit = false
  public Successfactor_update_status!: string
  public errorMessagesSf4cSync!: any
  public email!: string
  public loading = false
  public loading_ = false
  //modal jobReqId
  public arr_checkboxJobReqId: string[] = []
  formCheckBoxJobReqId = this.fb.group({
    checkResumeArrayJobReqId: this.fb.array([])

  })
  errorMessagesGetJr: any
  list_jr: Jr[] = []
  public page = 1
  public pageSize = 5
  public pageCheckValid = 1;

  //matching jr
  public matching_jr: Matching_JR = new Matching_JR()
  public errorMessagesMatchingJr: any
  public selectedJr!: string
  public items_jr: any[] = []
  public loading_match = false
  //sf4c upload
  public arr_checkboxJr: string[] = []
  public arr_checkbox_current_title: string[] = []
  public arr_checkboxValid: any[] = []
  public resultJr: Jr[] = []
  totalItemsJr: number = 0
  public have_Jr = false
  errorMessagesSf4cUpload: any
  public searchText: string = '';
  public basicSelectedOption: number = 5;
  public pageJr = 1
  //Censor
  public display_decensor = false
  // formCheckBoxJr!: FormGroup;

  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([], Validators.required)
  })
  formCheckBoxValid = this.fb.group({
    checkResumeArrayValid: this.fb.array([]),
    importedby: [null],
    source: [null],
  })
  //JA
  public selectedRowsJA: ResumeParser[] = [];
  public loadingFormJA = false;
  public role: string;
  public progressbarHeight = '.857rem';
  public resultCheckValidUpload: any
  totalResume: number
  currentResumeIndex: number
  resumeList: any[]
  resumeId: any
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;
  public isLoading = false
  showJrMatching = false
  public listUser: any[] = []
  public selectedUsers: string[] = [];
  public shareResumeForm: FormGroup;
  public listShared: any[] = []
  public shareUserBoolean = false
  public owner: any
  private language: string;
  public talentPool: TalentPool;
  public sf4cPicklist: any
  public talentPools: any[] = []
  public addResumeToTalentPoolForm: FormGroup;
  public list_all_jd: CV_task[] = []
  public arr_checkboxSaveCv: any
  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCvJr: this.fb.array([], Validators.required)

  })
  public arr_checkboxSaveCvJr: any
  public pageScoreExperience=1
  public pageSizeScoreExperience=5
  public pageScoreSkill=1
  public pageSizeScoreSkill=5
  public pageScoreEducation=1
  public pageSizeScoreEducation=5
  public pageScoreLanguage=1
  public pageSizeScoreLanguage=5
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private resumeService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _authenticationService: AuthenticationService,
    private talentPoolService: TalentPoolService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.display_decensor = false
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        },
        menu: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
    this.shareResumeForm = this.fb.group({
      share_to: [null, Validators.required]
    })
    this.addResumeToTalentPoolForm = this.fb.group({
      talent_pool: ["", Validators.required]
    })
    // this.formCheckBoxJr = new FormGroup({
    //   checkResumeArrayJr: new FormControl([], Validators.required),
    // })
  }
  //assign to user
  customSelectAll() {
    this.selectedUsers = this.listUser.map(x => x.id);
  }

  customUnselectAll() {
    this.selectedUsers = [];
  }
  modalOpenShareUser(modalBasicShareUser: any) {
    this.getListUser();
    this.modalService.open(modalBasicShareUser, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
    });
  }
  shareResume() {
    let share_to = this.shareResumeForm.get("share_to").value;
    this.resumeService.shareResume(this.resumeId, share_to).subscribe((res) => {
      this.shareResumeForm.reset()
      this.GetDetailResume()
    },
      (err) => {
        if (this.language == 'en') {
          this.toastr.error(err.error.message.en);
        } else {
          this.toastr.error(err.error.message.vn)
        }
      })
  }
  listSharedUser() {
    this.resumeService.listSharedUser(this.resumeId).subscribe((res) => {
      this.listShared = res.results
    },
      (err) => {
        console.log("err", err)
      }
    )
  }
  getListUser() {
    let url_dynamic = "?is_site_admin=false"
    this._authenticationService.listUsers(url_dynamic).subscribe(
      (res) => {
        let listUser = []
        for (let i = 0; i < res.results.length; i++) {
          if (res.results[i].email != this.user.email) {
            let owner__email_item = res.results[i].last_name + ' ' + res.results[i].first_name + ': ' + res.results[i].email
            listUser.push({ "name": owner__email_item, "email": res.results[i].email, "id": res.results[i].id })
          }
        }
        this.listUser = listUser
        // this.listUser = [...this.listUser]
        let emailsShared = this.listShared.map(item => item.share_to);
        let users = this.listUser.filter((e) =>
          !emailsShared.includes(e.email) && e.id != this.user.id && e.id != this.owner
        )
        this.listUser = users

      },
      (err) => {
        console.log("err", err)
      }
    )
  }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    this.listSharedUser()
  }
  shareUser(event: any) {
    let currentShared = this.listShared.filter((e) => e.id != event.id)
    this.listShared = currentShared
  }
  base64ToBlob(base64: any, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }
  gaugeColor(value: number) {
    if (value > 80) {
      return '#198754'
    } else if (value > 50) {
      return '#ffc107'
    } else {
      return '#dc3545'
    }
  }
  // matchingJrInit(){
  //   this.showJrMatching = true
  //   this.toastr.success(`The system starts scoring`)
  //   window.scroll({ 
  //     top: document.body.scrollHeight, 
  //     left: 0, 
  //     behavior: 'smooth' 
  //     });
  //     this.GetItemJR();

  //   // this.Matching_jr();
  // }
  Matching_jr() {
    this.loading_match = true
    let url_dynamic = "?jobReqId="
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    const api$ = this.resumeService.matching_jr(this.route.snapshot.params["id"], url_dynamic)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.resumeService.matching_jr(this.route.snapshot.params["id"], url_dynamic)
      )
    );
    this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
      (res) => {
        this.matching_jr = res[0];
        this.selectedJr = res[0].successfactor_jd;
        this.GetItemJR();
        if (this.matching_jr.matching_status == "Done" || this.matching_jr.matching_status == "Failure") {
          if (this.matching_jr.matching_status == "Failure") {
            this.errorMessagesMatchingJr = res[0].matching_message
          }
          this.toastr.success(
            this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.JOB_REQ_MATCHING"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Job Requisition matching`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
          this.showJrMatching = true
          this.intervalSubscription.unsubscribe()
          this.loading_match = false;
        }


      },
      (err) => {
        this.toastr.warning(err.error.message)
        this.loading_match = false
      }
    )
  }
  GetItemJR() {
    let owner = this.user.id
    let url_dynamic = "?has_jr=true" + '&owner=' + owner
    this.talentPoolService.list_all_jd(url_dynamic).subscribe(
      (res) => {
        this.items_jr = res.map((item) => ({
          jobReqId: item.hcm_system_jd,
          jobTitle: item.hcm_system_jd + ' - ' + item.job_title
        }));

      },
      (err) => {
        console.log(err)
      }
    )
  }
  Post_matching_jr() {
    this.loading_match = true
    let url_dynamic = "?jobReqId=" + this.selectedJr
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.resumeService.matching_jr(this.route.snapshot.params["id"], url_dynamic)
      )
    );
    this.intervalSubscription = intervalAfterCallApi.subscribe(
      (res) => {
        this.matching_jr = res[0];
        this.selectedJr = res[0].successfactor_jd;
        this.GetItemJR();
        if (this.matching_jr.matching_status == "Done" || this.matching_jr.matching_status == "Failure") {
          if (this.matching_jr.matching_status == "Failure") {
            this.errorMessagesMatchingJr = res[0].matching_message
          }
          this.toastr.success(
            this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.JOB_REQ_MATCHING"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
          this.showJrMatching = true
          this.intervalSubscription.unsubscribe()
          this.loading_match = false;
        }


      },
      (err) => {
        this.toastr.warning(err.error.message)
        this.loading_match = false
      }
    )
    // this.loading_match = true
    // let url_dynamic = "?jobReqId="+this.selectedJr
    // const payload = {
    //   id: this.route.snapshot.params["id"],
    //   url_dynamic: url_dynamic
    // }
    // this.resumeService.matching_jr(this.route.snapshot.params["id"],url_dynamic).subscribe(
    //   (res)=>{
    //     this.matching_jr = res[0];
    //     // this.selectedJr = this.matching_jr.successfactor_jd;
    //     this.loading_match = false;
    //   },
    //   (err)=>{
    //     this.loading_match = false;
    //     this.toastr.warning(err.error.message)
    //     this.errorMessagesMatchingJr = err?.statusText
    //   }
    // )
  }
  GetDetailResume() {
    this.isLoading = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.resumeService.getDetailResume(this.route.snapshot.params["id"]).subscribe(
      (res) => {
        this.owner = res.owner
        this.pdf_content = res.pdf_content;
        this.resume_parser = res;
        this.listSharedUser()
        const blob = this.base64ToBlob(this.pdf_content, 'application/pdf');
        this.url = URL.createObjectURL(blob);
        if (res.email != null && res.sf4c_status == 'SF4C_uploaded' && this.tag_resultConnectSf4c_exit) {
          this.email = res.email;
          this.GetSf4cCandidate();
        }
        this.isLoading = false

        this.tag_detail = true
      },
      (err) => {
        this.isLoading = false
        console.log(err)
        this.toastr.error(err.error.detail)
        this.errorMessagesParseResume = err
      }
    )
  }
  swapLink(index: number) {
    let currentHref = window.location.href
    let idJob: any
    if (currentHref.includes("manage-resume")) {
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/manage-resume/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnManageResumePage();
    } else if (currentHref.includes("talent-pool")) {
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/talent-pool/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnTalentPoolPage();
    }
    else if(currentHref.includes("integration")) {
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/integration/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnIntegrationResumePage();
    } else if(currentHref.includes("search-resume")){
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/search-resume/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnSearchResumePage();
    } else if(currentHref.includes("recommend-resume")){
      idJob = currentHref.split("manage-job/")[1].split("/recommend-resume")[0]
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/manage-job/${idJob}/recommend-resume/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnRecommendResumePage();
    } else if(currentHref.includes("saved-resume")){
      idJob = currentHref.split("manage-job/")[1].split("/saved-resume")[0]
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/manage-job/${idJob}/saved-resume/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnSavedResumePage();
    }
    else{
      if (index < this.totalResume && index >= 0) {
        this._router.navigate([`/ja/detail-resume/${this.resumeList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnJAResumePage();
    }
  }
  returnPage() {
    let currentHref = window.location.href
    if (currentHref.includes("manage-resume")) {
      this.returnManageResumePage();
    } else if (currentHref.includes("talent-pool")) {
      this.returnTalentPoolPage();
    }else if(currentHref.includes("integration")){
      this.returnIntegrationResumePage();
    }else if(currentHref.includes("search-resume")){
      this.returnSearchResumePage();
    }else if(currentHref.includes("recommend-resume")){
      this.returnRecommendResumePage();
    }else if(currentHref.includes("saved-resume")){
      this.returnSavedResumePage();
    }
    else {
      this.returnJAResumePage();
    }
  }
  returnManageResumePage() {
    this._router.navigate([`/manage-resume`]);
  }
  returnIntegrationResumePage() {
    this._router.navigate([`/integration`]);
  }
  returnJAResumePage(){
    this._router.navigate([`/integration/tab-ja`]);
  }
  returnTalentPoolPage() {
    this._router.navigate([`/talent-pool/${this.talentPool.id}`])
  }
  returnSearchResumePage(){
    this._router.navigate([`/search-resume`]);
  }
  returnRecommendResumePage(){
    let idJob: any
    idJob = window.location.href.split("manage-job/")[1].split("/recommend-resume")[0]
    this._router.navigate([`/manage-job/recommend-resume/${idJob}`]);
  }
  returnSavedResumePage(){
    let idJob: any
    idJob = window.location.href.split("manage-job/")[1].split("/saved-resume")[0]
    this._router.navigate([`/manage-job/saved-cvs/${idJob}`]);
  }
  modalOpenSaveCv(modalBasicSaveCv: any) {
    this.ListAllJd()
    this.modalService.open(modalBasicSaveCv, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  ListAllJd() {
    let owner = this.user.id
    let url_dynamic = '?owner=' + owner
    this.talentPoolService.list_all_jd(url_dynamic).subscribe(
      (res) => {
        this.list_all_jd = res;
        this.talentPoolService.setNewListJd(res)

      },
      (err) => {
        console.log("err", err)
      }
    )
  }
  SearchJr() {
    // alert(this.searchText)
    let resultJr_cp: any
    this.talentPoolService.listJd.subscribe(
      (res) => {
        resultJr_cp = res
      }
    )
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();
      this.list_all_jd = resultJr_cp.filter((resultJr_: any) => {
        return resultJr_.job_title.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here
      });
    }
    else {
      this.list_all_jd = resultJr_cp
    }
  }
  onCheckboxChangeSaveCv(e: any) {
    let checkResumeArraySaveCv: any = this.formCheckBoxSaveCv.get('checkResumeArraySaveCv');
    if (e.target.checked) {
      checkResumeArraySaveCv = new FormControl(e.target.value);
      this.arr_checkboxSaveCv = e.target.value
    } else {
      checkResumeArraySaveCv = new FormControl()
      this.arr_checkboxSaveCv = null
    }
  }
  onCheckboxChangeSaveCvJr(e: any) {
    let checkResumeArraySaveCvJr: any = this.formCheckBoxSaveCv.get('checkResumeArraySaveCvJr');
    if (e.target.checked) {
      checkResumeArraySaveCvJr = new FormControl(e.target.value);
      this.arr_checkboxSaveCvJr = e.target.value
    } else {
      checkResumeArraySaveCvJr = new FormControl()
      this.arr_checkboxSaveCvJr = null
    }
  }
  FormCheckBoxSaveCv() {
    let resume = this.resume_parser.candidate
    let job_description = parseInt(this.arr_checkboxSaveCv)
    this.talentPoolService.save_resume_by_jd(job_description, [resume]).subscribe(
      (res) => {
        this.arr_checkboxSaveCv = null
        this.searchText = ''
        this.modalService.dismissAll();
        // this.toastr.success('Save Success')
      },
      (err) => {
        this.arr_checkboxSaveCv = null
        this.searchText = ''
        err.error.detail ? this.toastr.error(
          this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")
        ) : this.toastr.error(err.error.message)
      }
    )
  }
  ApproveResume() {
    let formData: any = {}
    if (this.resume_parser.estimate_dob) {
      formData['estimate_dob'] = this.resume_parser.estimate_dob
    }
    this.resumeService.approveResume(this.route.snapshot.params["id"], formData).subscribe((res) => {
      if (res) {
        this.GetDetailResume()
      }
    },
      (err) => {
        if (this.language == 'en') {
          this.toastr.warning(err.error.message.en.join(', '))
        } else {
          this.toastr.warning(err.error.message.vn.join(', '))
        }
      })
  }
  CensorResume() {
    this.resumeService.getCensorResume(this.route.snapshot.params["id"]).subscribe(
      (res) => {
        if (res.status == "success") {
          this.display_decensor = true;
          this.pdf_content = res.pdf_content;
          const blob = this.base64ToBlob(this.pdf_content, 'application/pdf');
          this.url = URL.createObjectURL(blob);
        } else {
          this.pdf_content = this.resume_parser.pdf_content;
          const blob = this.base64ToBlob(this.pdf_content, 'application/pdf');
          this.url = URL.createObjectURL(blob);
        }
      },
      (err) => {
        console.log("er", err)
        this.toastr.warning(err.error.message)
      }
    )
  }
  DeCensorResume() {
    this.resumeService.getDeCensorResume(this.route.snapshot.params["id"]).subscribe(
      (res) => {
        this.display_decensor = false;
        this.pdf_content = res.pdf_content;
        const blob = this.base64ToBlob(this.pdf_content, 'application/pdf');
        this.url = URL.createObjectURL(blob);
      },
      (err) => {
        this.errorMessagesDeCensorResume = err
      }
    )
  }
  Tab() {
    this.tab_sf4c = false
    // window.location.reload()
  }
  Tab_sf4c() {
    this.tab_sf4c = true
  }
  GetSf4cCandidate() {
    this.isLoading = true
    const payload = {
      email: this.email
    }
    this.resumeService.getSf4cCandidate(this.email).subscribe(
      (res) => {
        this.sf4cCandidate = res;
        this.pdf_content_sf4c = res.resume != null ? res.resume.fileContent : this.pdf_content;
        const blob = this.base64ToBlob(this.pdf_content_sf4c, 'application/pdf');
        this.url_sf4c = URL.createObjectURL(blob);
        this.tag_sf4c_candidate = true
        this.isLoading = false
      },
      (err) => {
        this.isLoading = false
        this.toastr.warning(err.error.message)
        this.errorMessagesGetSf4cCandidate = err
      }
    )
  }
  //mapping
  onCheckboxChangeJobReqId(e: any) {
    const checkResumeArrayJobReqId: FormArray = this.formCheckBoxJobReqId.get('checkResumeArrayJobReqId') as FormArray;
    if (e.target.checked) {

      checkResumeArrayJobReqId.push(new FormControl(e.target.value));
      this.arr_checkboxJobReqId.push(e.target.value)


    } else {
      let i: number = 0;
      checkResumeArrayJobReqId.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJobReqId.removeAt(i);
          this.arr_checkboxJobReqId = this.arr_checkboxJobReqId.filter(item => item !== e.target.value)
          return;
        }
        i++;
      });
    }
  }
  modalOpenJobReqId(modalBasicJobReqId: any) {
    this.getJr()
    this.modalService.open(modalBasicJobReqId, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
      scrollable: true
    });
  }

  //sf4c upload
  modalOpenUploadSf4c(modalBasic: any, JaBoolean = false) {
    this.have_Jr = false
    this.getJr(JaBoolean);
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      backdrop: "static",
      scrollable: false
    });
  }
  modalOpenCheckValidUpload(modalCheckValid: any) {
    this.modalService.open(modalCheckValid, {
      windowClass: 'modal',
      size: 'lg',
      backdrop: "static",
      scrollable: true
    });
  }
  getJr(JaBoolean: boolean = false) {
    let url_dynamic = ""
    let owner = this.user.id
    this.loading = true
    this.resumeService.getJrWithQuery(owner).subscribe(
      (res) => {
        this.resultJr = res;
        if (JaBoolean) {
          this.resumeService.getJobApplicationStatus(this.resumeId).subscribe(
            (result) => {
              let jaStatusCurrent = result.filter((e) => e.status == "Success")
              const ids = jaStatusCurrent.map((row) => row.job_req_id);
              this.resultJr = this.resultJr.filter((item) =>
                !ids.includes(item.hcm_system_jd)
              )
            }
          )
        }
        this.resumeService.setListJrValue(this.resultJr)
        this.totalItemsJr = res.length;
        this.have_Jr = true;
        this.loading = false
      },
      (err) => {
        console.error("Error: " + err);
        this.loading = false
      }
    )
  }

  onCheckboxChangeJr(e: any) {
    const checkResumeArrayJr: FormArray = this.formCheckBoxJr.get('checkResumeArrayJr') as FormArray;
    if (e.target.checked) {

      checkResumeArrayJr.push(new FormControl(e.target.value));

      this.arr_checkboxJr.push(e.target.value)

    } else {
      let i: number = 0;
      checkResumeArrayJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJr.removeAt(i);
          this.arr_checkboxJr = this.arr_checkboxJr.filter(item => item !== e.target.value)
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeValid(e: any) {
    const checkResumeArrayValid: FormArray = this.formCheckBoxValid.get('checkResumeArrayValid') as FormArray;
    if (e.target.checked) {

      checkResumeArrayValid.push(new FormControl(e.target.value));

      this.arr_checkboxValid.push(Number(e.target.value))
    } else {
      let i: number = 0;
      checkResumeArrayValid.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayValid.removeAt(i);
          this.arr_checkboxValid = this.arr_checkboxValid.filter(item => item !== Number(e.target.value))
          return;
        }
        i++;
      });
    }
  }
  FormCheckValidUpload(modalCheckValid: any, JaBoolean = false) {
    if (JaBoolean) {
      this.loading_ = true
      const id = [Number(this.route.snapshot.paramMap.get('id'))]
      let jobReqId = this.arr_checkboxJr

      this.resumeService.validUploadJa(id, jobReqId).subscribe(
        (res) => {
          this.resultCheckValidUpload = res;
          this.loading_ = false
          if (res.invalid_job_app_id.length > 0 && !res.invalid_job_app_id[0].overwrite) {
            this.toastr.error(res.invalid_job_app_id[0].error)
          }
          else if (res.invalid_job_app_id.length > 0 && res.invalid_job_app_id[0].overwrite) {
            this.modalOpenCheckValidUpload(modalCheckValid)
            this.loading_ = false
          } else {
            this.FormCheckBoxJA()
          }

          // this.modalService.dismissAll();
        },
        (err) => {
          this.errorMessagesSf4cUpload = err;
          this.loading_ = false
        }
      )
    } else {
      this.loading_ = true

      const id = [Number(this.route.snapshot.paramMap.get('id'))]
      let jobReqId = this.arr_checkboxJr

      this.resumeService.validUpload(id, jobReqId).subscribe(
        (res) => {
          this.resultCheckValidUpload = res;
          if (res.invalid_resume_id.length > 0) {
            this.modalOpenCheckValidUpload(modalCheckValid)
            this.loading_ = false
          } else {
            this.FormCheckBoxJr()
          }
          // this.modalService.dismissAll();
        },
        (err) => {
          this.errorMessagesSf4cUpload = err;
          this.loading_ = false
        }
      )
    }

  }
  cancel() {
    this.searchText = ''
    this.formCheckBoxValid.reset();
    this.formCheckBoxJr.reset();
  }
  FormCheckBoxJr() {
    this.loading_ = true
    let formData = {}
    let arr_checkbox_upload: any = this.resultCheckValidUpload.resume_id.concat(this.arr_checkboxValid)
    let importedby = this.formCheckBoxValid.value.importedby
    if (window.location.href.includes('talent-pool')) {
      let name_talent_pool = this.resumeService.talentPoolValue.name
      formData['CHRTalentpool'] = name_talent_pool;
    }
    formData['resume_id'] = arr_checkbox_upload;
    formData['jobReqId'] = this.arr_checkboxJr;
    if (importedby) {
      formData['importedby'] = importedby.id;
      formData['source'] = importedby.source_id;
    }
    this.resumeService.sf4c_upload(formData).subscribe(
      (res) => {
        if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
        const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
          switchMap(() =>
            this.resumeService.getDetailResume(this.route.snapshot.params["id"])
          )
        );
        this.intervalSubscription = intervalAfterCallApi.subscribe(
          (res) => {
            this.pdf_content = res.pdf_content;
            this.resume_parser = res;
            const blob = this.base64ToBlob(this.pdf_content, 'application/pdf');
            this.url = URL.createObjectURL(blob);


            if (this.resume_parser.sf4c_status == "SF4C_uploaded" || this.resume_parser.sf4c_status == "SF4C_failed") {
              this.intervalSubscription.unsubscribe()
              this.formCheckBoxValid.reset();
              this.formCheckBoxJr.reset();
              arr_checkbox_upload = []
              this.arr_checkboxJr = []
              this.arr_checkboxValid = []
              this.searchText = ""
              this.loading_ = false;
              this.modalService.dismissAll();
            }

          },
          (err) => {
            console.log("err", err)
            this.loading_ = false
          }
        )
        // this.toastr.success("Upload success")
        // arr_checkbox_upload = []
        // this.arr_checkboxJr = []
        // this.arr_checkboxValid = []
        // this.searchText = ""
        // this.modalService.dismissAll();
        // this.formCheckBoxValid.reset();
        // this.formCheckBoxJr.reset();
        // this.loading_ = false
      },
      (err) => {
        arr_checkbox_upload = []
        this.arr_checkboxJr = []
        this.arr_checkboxValid = []
        this.searchText = ""
        this.toastr.warning(err.error.message)
        this.loading_ = false

      }
    )

  }
  cancelFormCheckboxValid() {
    this.searchText = ''
    this.arr_checkboxJr = []
    // this.formCheckBoxValid.reset()
  }
  cancelFormCheckBoxJr() {
    // this.searchText = ''
    this.arr_checkboxJr = []
    this.arr_checkboxValid = []
    // this.formCheckBoxJr.reset()
  }
  FormCheckBoxJA() {
    this.loadingFormJA = true
    let formData = {}
    let importedby = this.formCheckBoxValid.value.importedby
    let source = this.formCheckBoxValid.value.source
    formData['resume_id'] = [Number(this.route.snapshot.params["id"])]
    formData['jobReqId'] = this.arr_checkboxJr;
    if (importedby) {
      formData['importedby'] = importedby;
    }
    if (source) {
      formData['source'] = source;
    }
    this.resumeService.updateJA(formData).subscribe(
      (res) => {
        if (res) {
          this.selectedRowsJA = []
          this.searchText = ""
          this.modalService.dismissAll();
          this.loadingFormJA = false
          this.formCheckBoxJr.reset();
          this.formCheckBoxValid.reset();
          this.arr_checkboxJr = []
          this.GetDetailResume()
        }
      },
      (err) => {
        this.arr_checkboxJr = []
        this.formCheckBoxJr.reset();
        this.formCheckBoxValid.reset();
        this.errorMessagesSf4cUpload = err[0]
        this.toastr.warning(err.error.message)
        this.modalService.dismissAll();
        this.loadingFormJA = false
      }
    )
  }
  Search() {
    // alert(this.searchText)
    let resultJr_cp: any
    this.resumeService.listJr.subscribe(
      (res) => {
        resultJr_cp = res
      }
    )
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();
      this.resultJr = resultJr_cp.filter((resultJr_: any) => {
        return resultJr_.job_title.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here
      });
    }
    else {
      this.resultJr = resultJr_cp
    }
  }
  getListCredential() {
    this.resumeService.getDetailCredential(this.user.id).subscribe(
      (res) => {
        if (res) {
          this.tag_resultConnectSf4c_exit = true
          this.GetItemJR();
          // this.Matching_jr();
        }
      }
    )
  }
  getSf4cPicklist() {
    this.resumeService.sf4cPicklist().subscribe((res) => {
      this.sf4cPicklist = res
    })
  }

  //addtalent pool
  getListTalentPool() {
    let url_dynamic = 'owner__email=' + this.user.email
    this.resumeService.listTalentPool(url_dynamic).subscribe((res) => {
      this.talentPools = res
      // this.resumeService.setListFolderValue(this.talentPools)
    })
  }
  onAddResumeToTalentPool() {
    const formData = {};
    formData['resumes'] = [this.resumeId]
    formData['talent_pool'] = this.addResumeToTalentPoolForm.get("talent_pool").value
    this.resumeService.addResumes(formData).subscribe((res) => {
      this.addResumeToTalentPoolForm.reset();
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err)
    })
  }
  // modal Basic
  modalOpenAddTalentPool(modalAddTalentPool: any) {

    this.modalService.open(modalAddTalentPool, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
    });
  }
  modalOpenDetailScore(modalBasicDetailScore: any){
    this.modalService.open(modalBasicDetailScore, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  // isOverwrite(){
  //   if(this.resultCheckValidUpload.invalid_resume_id.every((value)=>value.))
  // }
  ngOnInit(): void {
    // this.user = JSON.parse(localStorage.getItem("user")|| '{}');
    this.route.paramMap.subscribe((params) => {
      this.display_decensor = false
      this.showJrMatching = false
      this.selectedJr = null
      this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          menu: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      };
      this.user = this._authenticationService.currentUserValue
      this.resumeId = params.get("id");
      this.talentPool = this.resumeService.talentPoolValue
      if(window.location.href.includes("search-resume")||
      window.location.href.includes("recommend-resume")||
      window.location.href.includes("saved-resume")){
        this.talentPoolService.listCandidate.subscribe((res) => {
          this.resumeList = res
          this.totalResume = res.length
          this.resumeList.every((resume: any, index: number) => {
            if (resume.id == this.resumeId) {
              this.currentResumeIndex = index;
              return false;
            }
            return true;
          });
        })
      }else{
        this.resumeService.listResume.subscribe((res) => {
          this.resumeList = res
          this.totalResume = res.length
          this.resumeList.every((resume: any, index: number) => {
            if (resume.id == this.resumeId) {
              this.currentResumeIndex = index;
              return false;
            }
            return true;
          });
        })
      }
      if (this.user.role != 'Free trial user' && !this.user.is_superuser) {
        this.getListCredential();
      }
      this.GetDetailResume();
      this.getSf4cPicklist()
      this.getListTalentPool()
      // if(this.tag_resultConnectSf4c_exit){
      //   this.Matching_jr();
      // }
    })

  }

}
