import { colors } from './../../../../../colors.const';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Jr } from 'app/main/resume-parser/models/resume.model';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { CV_task } from 'app/main/talent-pool/models/job.model';
import { SearchResume, status_color } from 'app/main/talent-pool/models/resume.model';
import { TalentPoolService } from 'app/main/talent-pool/services/talent-pool.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { locale as enLocale } from "../../../i18n/en"
import { locale as vnLocal } from "../../../i18n/vn"
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { User } from 'app/auth/models';

@Component({
  selector: 'app-resume-talent-pool',
  templateUrl: './resume-talent-pool.component.html',
  styleUrls: ['./resume-talent-pool.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResumeTalentPoolComponent implements OnInit {
  @Input() ResultSearchCv: SearchResume = new SearchResume()
  @Output() newItemEvent = new EventEmitter<any>();
  @Output() removeItemEvent = new EventEmitter<any>();
  @Input() jd_id!: number
  @Input() hcm_system_jd: number
  @Input() saved!: boolean
  @Input () pageSaved: boolean
  @Input () formData: any

  public sf4cCandidate:any[]=[]
  public detail_jd: CV_task=new CV_task()


  public arr_checkboxSaveCv:any
  public arr_checkboxSaveCvJr:any
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;
  
  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCv: this.fb.array([],Validators.required),
    checkResumeArraySaveCvJr: this.fb.array([],Validators.required)

  })
  public list_task: CV_task[] =[]
  public list_jr: Jr[] = []
  public save_cv: string = ''
  public saved_cv = false;
  public positive = false
  public negative = false
  public page=1
  public pageSize=5
  public page_sf4c=1
  public pageSize_sf4c=5
  public pageScoreExperience=1
  public pageSizeScoreExperience=5
  public pageScoreSkill=1
  public pageSizeScoreSkill=5
  public pageScoreEducation=1
  public pageSizeScoreEducation=5
  public pageScoreLanguage=1
  public pageSizeScoreLanguage=5
  public currentTitles: string[] = []
  public currentCompanies: string[] = []

  public errorMessagesSaveResumeByJd: any
  public errorMessagesListAllJd!: any
  public list_all_jd:CV_task[]=[]
  public searchText: string
  public status_color=status_color
  isLoading = false
  public progressbarHeight = '.857rem';
  public user: User = new User()
  checkShared = false
  public comment: string
  public search_history = false
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private talentPoolService: TalentPoolService,
    private resumesService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _authenticationService: AuthenticationService,


  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
  }
  
  modalOpenSaveCv(modalBasicSaveCv:any) {
    this.ListAllJd()
    this.modalService.open(modalBasicSaveCv, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true,
      backdrop: "static",
    });
    }
  modalReportUnmatch(modalBasicReportUnmatch: any){
    this.modalService.open(modalBasicReportUnmatch, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true,
      backdrop: "static",
    });
  }
  modalOpenDetailExperience(modalBasicDetailExperience: any){
    this.modalService.open(modalBasicDetailExperience, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  ListAllJd(){
    let owner = this.user.id
    let url_dynamic = '?owner=' + owner
    this.talentPoolService.list_all_jd(url_dynamic).subscribe(
      (res) => {
        this.list_all_jd = res;
        this.talentPoolService.setNewListJd(res)

      },
      (err) => {
        this.errorMessagesListAllJd = err
      }
    )
  }
  Search(){
    // alert(this.searchText)
    let resultJr_cp :any
    this.talentPoolService.listJd.subscribe(
      (res)=>{
        resultJr_cp = res
      }
    )
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      this.list_all_jd = resultJr_cp.filter((resultJr_:any) =>{
        return resultJr_.job_title.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here
            });   
          }  
          else{
            this.list_all_jd = resultJr_cp
          }
        }
  onCheckboxChangeSaveCv(e:any) {
    let checkResumeArraySaveCv: any = this.formCheckBoxSaveCv.get('checkResumeArraySaveCv');
    if (e.target.checked) {
      checkResumeArraySaveCv = new FormControl(e.target.value);
      this.arr_checkboxSaveCv = e.target.value
    } else {
      checkResumeArraySaveCv = new FormControl()
      this.arr_checkboxSaveCv = null
    }
  }
  onCheckboxChangeSaveCvJr(e:any) {
    let checkResumeArraySaveCvJr: any = this.formCheckBoxSaveCv.get('checkResumeArraySaveCvJr');
    if (e.target.checked) {
      checkResumeArraySaveCvJr = new FormControl(e.target.value);
      this.arr_checkboxSaveCvJr = e.target.value
    } else {
      checkResumeArraySaveCvJr = new FormControl()
      this.arr_checkboxSaveCvJr = null
    }
  }
  FormCheckBoxSaveCv(resume: number){
    let job_description= parseInt(this.arr_checkboxSaveCv)
    this.talentPoolService.save_resume_by_jd(job_description, [resume]).subscribe(
      (res)=>{
        this.arr_checkboxSaveCv = null
        this.searchText= ''
        // this.toastr.success('Save Success')
      },
      (err)=>{
        this.arr_checkboxSaveCv = null
        this.searchText= ''
        err.error.detail?this.toastr.error(
          this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")
        ):this.toastr.error(err.error.message)
        this.errorMessagesSaveResumeByJd = err
      }
    )
  }
  SaveResumeByJd(resume: number){
    this.talentPoolService.save_resume_by_jd(this.jd_id, [resume]).subscribe(
      (res)=>{
        // this.toastr.success('Save Success');
        this.saved = true
        this.newItemEvent.emit(resume);
      },
      (err)=>{
        this.arr_checkboxSaveCv = null
        this.searchText= ''
        err.error.detail?this.toastr.error(this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")):this.toastr.error(err.error.message)
        this.errorMessagesSaveResumeByJd = err;
      }
    )
  }
  UnsaveResumeByJd(resume: number){
    Swal.fire({
      title: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.DELETE_R"),
      text: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.talentPoolService.unSaveCandidate(this.jd_id, resume).subscribe(
          (res)=>{
            // this.toastr.success('Delete Success');
            this.removeItemEvent.emit(resume)
          },
          (err)=>{
            console.log(err)
          }
        )
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
    
  }
  cancel(){
    this.searchText=''
    this.arr_checkboxSaveCv = null
  }
  modalSf4cStatusCandidate(modalBasicSf4cStatus:any,info:any) {
    this.getSf4cStatusCandidate(info.id)
    this.modalService.open(modalBasicSf4cStatus, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
  getSf4cStatusCandidate(id: number){
    this.isLoading = true
    this.talentPoolService.sf4cSf4cCandidate(id).subscribe((e)=>{
      this.sf4cCandidate = e.result
      this.isLoading=false
    },
    (err)=>{
      this.isLoading = false
    })
  }
  requestToShare(){
    var formData: any = {}
    formData["owner"] = this.ResultSearchCv?.owner
    this.talentPoolService.requestToShare(this.ResultSearchCv?.id, formData).subscribe(
      (res)=>{
        console.log("AA",res)
      },
      (err)=>{
        this.toastr.error(err.error.message)
      }
    )
  }
  toDetailResume(id_resume: any){
    let job_description = Number(this.jd_id);
    let resume = id_resume
    this.talentPoolService.seen_resumes(job_description, resume).subscribe((res)=>{
      this._router.navigate([`/manage-job/${this.jd_id}/saved-resume/detail-resume/${id_resume}`]);
    }, (err)=>{
      this._router.navigate([`/manage-job/${this.jd_id}/saved-resume/detail-resume/${id_resume}`]);
    });
    
  }
  cancelReport(){
    this.comment = ''
  }
  reportUnmatchResume(){
    let formData = {}
    let search_history = {}
    if(this.jd_id){
      search_history['job_description_id'] = this.jd_id
    }
    if (this.formData.from) {
      search_history['from'] = this.formData.from
    }
    if (this.formData.to) {
      search_history['to'] = this.formData.to
    }
    if (this.formData.user_talentpool) {
      search_history['user_talentpool'] = this.formData.user_talentpool
    }
    if (this.formData.company_talentpool) {
      search_history['company_talentpool'] = this.formData.company_talentpool
    }
    if (this.formData.job_title) {
      search_history['job_title'] = this.formData.job_title
    }
    if (this.formData.city?.length > 0) {
      search_history['city'] = this.formData.city
    }
    if(this.formData.user_talentpool){
      search_history['user_talentpool'] = this.formData.user_talentpool
    }
    if (this.formData.fulltext_bool_expression) {
      search_history['fulltext_bool_expression'] = this.formData.fulltext_bool_expression
    }
    if (this.formData.full_name_bool_expression) {
      search_history['full_name_bool_expression'] = this.formData.full_name_bool_expression
    }
    if (this.formData.education_bool_expression) {
      search_history['education_bool_expression'] = this.formData.education_bool_expression
    }
    if (this.formData.experience_bool_expression) {
      search_history['experience_bool_expression'] = this.formData.experience_bool_expression
    }
    if (this.formData.current_title_list?.length>0) {
      search_history['current_title_list'] = this.formData.current_title_list
    }
    if (this.formData.current_company_list?.length>0) {
      search_history['current_company_list'] = this.formData.current_company_list
    }
    if (this.formData.category?.length > 0) {
      search_history['category'] = this.formData.category
    }
    if (this.formData.skills?.length > 0) {
      search_history['skillss'] = this.formData.skill
    }
    if(this.formData.keywords?.length > 0){
      search_history['keywords'] = this.formData.keywords
    }
    if (this.formData.years_of_experience_from != null) {
      search_history['years_of_experience_from'] = this.formData.years_of_experience_from
    }
    if (this.formData.years_of_experience_to != null) {
      search_history['years_of_experience_to'] = this.formData.years_of_experience_to
    }
    if(search_history){
      formData['search_history'] = search_history
    }
    formData['resume_id'] = this.ResultSearchCv.id
    formData['comment'] = this.comment
    this.talentPoolService.report_unmatch_resume(formData).subscribe((res)=>{
      this.modalService.dismissAll();
      this.comment = null
    },
    (err)=>{
      this.toastr.error(err.error.message)
    })

  }
  ngOnInit(): void {
    this.user = this._authenticationService.currentUserValue
    this.currentTitles = this.ResultSearchCv.current_title?this.ResultSearchCv.current_title.split('; '):[]
    this.currentCompanies = this.ResultSearchCv.current_company?this.ResultSearchCv.current_company?.split('; '):[]
    if(this.formData){
      if(Object.keys(this.formData)?.length>1){
        this.search_history = true
      }
    }
  }

}
