import { status } from 'app/main/talent-pool/models/resume.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, interval, of, throwError } from 'rxjs';
import { CHR_Picklist, Jr, ListResumeParser, Mapping_Picklist, Matching_JR, ResultConnectSf4c, ResultResume, ResultResumeSettings, ResultSourceMapping, ResultUpload, ResumeParser, Sf4cCandidate, Sf4cUpload, Stat, StatResumeMatching, Status, Successfactor_update_status, TalentPool } from '../models/resume.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { CoreTranslationService } from '@core/services/translation.service';
import { Page } from '../models/paging.model';
const FILTER_DOCUMENTS_PAGE_DATA_KEY = "filter_documents_page_data";
const FILTER_DOCUMENTS_PAGE_INTEGRATION = "filter_documents_page_integration";
const FILTER_DOCUMENTS_PAGE_JA = "filter_documents_page_ja";
const FILTER_FOLDER_PAGE_TALENTPOOL = "filter_folder_page_talentpool";
const FILTER_DOCUMENTS_PAGE_TALENTPOOL = "filter_documents_page_talentpool";



@Injectable({
  providedIn: 'root'
})
export class ResumeParserService {
  public listResume: Observable<ResumeParser[]>;
  private listResumeSubject: BehaviorSubject<ResumeParser[]>;
  public listJr: Observable<Jr[]>;
  private listJrSubject: BehaviorSubject<Jr[]>;
  public status: Observable<string>;
  private statusSubject: BehaviorSubject<string>;
  
  public talentPool: Observable<TalentPool>;
  private talentPoolSubject: BehaviorSubject<TalentPool>;
  public resultConnectSf4cBoolean: BehaviorSubject<string>;
  private resultConnectSf4cBooleanSubject: BehaviorSubject<string>;
  //
  private filterDocumentsPageSubject: BehaviorSubject<any>;
  private filterDocumentsPageIntegrationSubject: BehaviorSubject<any>;
  private filterDocumentsPageJaSubject: BehaviorSubject<any>;
  private filterFolderPageTalentpoolSubject: BehaviorSubject<any>;
  private filterDocumentsPageTalentpoolSubject: BehaviorSubject<any>;


  //
  private BASE_URL = `${environment.apiUrl}`;
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    public _translateService: TranslateService,
    private _coreTranslateService: CoreTranslationService,

  ) {
    this._coreTranslateService.translate(enLocale);
    this._coreTranslateService.translate(vnLocal);
    this.statusSubject = new BehaviorSubject<string>(localStorage.getItem("status"));
    this.status = this.statusSubject.asObservable();
    this.listResumeSubject = new BehaviorSubject<ResumeParser[]>(JSON.parse(localStorage.getItem("listResume")));
    this.listResume = this.listResumeSubject.asObservable();
    this.listJrSubject = new BehaviorSubject<Jr[]>(JSON.parse(localStorage.getItem("listJr")));
    this.listJr = this.listJrSubject.asObservable();
    this.talentPoolSubject = new BehaviorSubject<TalentPool>(JSON.parse(localStorage.getItem("talentPool")));
    this.talentPool = this.talentPoolSubject.asObservable();
    this.resultConnectSf4cBooleanSubject = new BehaviorSubject<string>(
      localStorage.getItem("resultConnectSf4cBoolean"))
    ;
    this.filterDocumentsPageSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_DOCUMENTS_PAGE_DATA_KEY)) || {}
    );
    this.filterDocumentsPageIntegrationSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_DOCUMENTS_PAGE_INTEGRATION)) || {}
    );
    this.filterDocumentsPageJaSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_DOCUMENTS_PAGE_JA)) || {}
    );
    this.filterFolderPageTalentpoolSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_FOLDER_PAGE_TALENTPOOL)) || {}
    );
    this.filterDocumentsPageTalentpoolSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_DOCUMENTS_PAGE_TALENTPOOL)) || {}
    );
  }
  
  get statusValue(): string {
    return this.statusSubject.value;
  }
  public setStatusValue(value: string) {
    this.statusSubject.next(value)
  }
  get listResumeValue(): ResumeParser[] {
    return this.listResumeSubject.value;
  }
  public setListResumeValue(value: ResumeParser[]) {
    this.listResumeSubject.next(value)
    localStorage.setItem("listResume", JSON.stringify(value));

  }
  get listJrValue(): Jr[] {
    return this.listJrSubject.value;
  }
  public setListJrValue(value: Jr[]) {
    this.listJrSubject.next(value)
    localStorage.setItem("listJr", JSON.stringify(value));

  }
  get resultConnectSf4cBooleanValue(): string {
    return this.resultConnectSf4cBooleanSubject.value;
  }
  public setResultConnectSf4cBooleanValue(value: string) {
    this.resultConnectSf4cBooleanSubject.next(value)
    localStorage.setItem("resultConnectSf4cBoolean", JSON.stringify(value));
  }
  get talentPoolValue(): TalentPool {
    return this.talentPoolSubject.value;
  }
  public setTalentPoolValue(value: TalentPool) {
    this.talentPoolSubject.next(value)
    localStorage.setItem("talentPool", JSON.stringify(value));

  }
  //
  setFilterDocumentsPageValue(
    page: Partial<Page>,
    file_name: string = null,
    selectedStatus: string[] = [],
    owner__email: string[] = [],
    source: string[] = [],
  ) {
    this.filterDocumentsPageSubject.next({
      page,
      file_name,
      selectedStatus,
      owner__email,
      source
    });

    // save page size, and docTypeId to local storage
    const pageCopy = { ...page, pageNumber: 0 };
    localStorage.setItem(
      FILTER_DOCUMENTS_PAGE_DATA_KEY,
      JSON.stringify({
        page: pageCopy,
      })
    );
  }

  getFilterDocumentsPageValue() {
    return this.filterDocumentsPageSubject.value;
  }
  //integration
  setFilterDocumentsPageIntegrationValue(
    page: Partial<Page>,
    file_name: string = null,
    owner__email: string[] = [],
    current_title: string = null
  ) {
    this.filterDocumentsPageIntegrationSubject.next({
      page,
      file_name,
      owner__email,
      current_title
    });

    // save page size, and docTypeId to local storage
    const pageCopy = { ...page, pageNumber: 0 };
    localStorage.setItem(
      FILTER_DOCUMENTS_PAGE_INTEGRATION,
      JSON.stringify({
        page: pageCopy,
      })
    );
  }

  getFilterDocumentsPageIntegrationValue() {
    return this.filterDocumentsPageIntegrationSubject.value;
  }
  //ja
  setFilterDocumentsPageJaValue(
    pageJA: Partial<Page>,
    file_nameJA: string = null,
    selectedSf4cStatus: string[] = [],
    owner__emailJA: string[] = [],
    current_titleJA: string = null,
    source: string[] = []
  ) {
    this.filterDocumentsPageJaSubject.next({
      pageJA,
      file_nameJA,
      selectedSf4cStatus,
      owner__emailJA,
      current_titleJA,
      source
    });

    // save page size, and docTypeId to local storage
    const pageCopy = { ...pageJA, pageNumber: 0 };
    localStorage.setItem(
      FILTER_DOCUMENTS_PAGE_JA,
      JSON.stringify({
        page: pageCopy,
      })
    );
  }

  getFilterDocumentsPageJaValue() {
    return this.filterDocumentsPageJaSubject.value;
  }

  //talent 
  setFilterFolderPageTalentpoolValue(
    searchText: string = null,
    owner__email: string[] = []
  ) {
    this.filterFolderPageTalentpoolSubject.next({
      searchText,
      owner__email
    });
  }

  getFilterFolderPageTalentpoolValue() {
    return this.filterFolderPageTalentpoolSubject.value;
  }
  setFilterDocumentsPageTalentpoolValue(
    page: Partial<Page>,
    file_name: string = null,
    selectedStatus: string[] = [],
    owner__email: string[] = [],
    source: string[] = []
  ) {
    this.filterDocumentsPageTalentpoolSubject.next({
      page,
      file_name,
      selectedStatus,
      owner__email,
      source
    });

    // save page size, and docTypeId to local storage
    const pageCopy = { ...page, pageNumber: 0 };
    localStorage.setItem(
      FILTER_DOCUMENTS_PAGE_TALENTPOOL,
      JSON.stringify({
        page: pageCopy,
      })
    );
  }

  getFilterDocumentsPageTalentpoolValue() {
    return this.filterDocumentsPageTalentpoolSubject.value;
  }
  parseResume(file: File): Observable<ResultResume> {
    const profileData = new FormData();
    profileData.append("file", file);
    const url = `${this.BASE_URL}/parse-resume/`;
    let headers = new HttpHeaders();
    // headers = headers.append('content-type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    const httpOptions = {
      headers: headers
    };
    return this._http.post<ResultResume>(url, profileData, httpOptions)
  }
  //get resume parser
  getListResume(page: number, page_size: number): Observable<ListResumeParser> {
    const url = `${this.BASE_URL}/resume/?page=${page + 1}&page_size=${page_size}`;
    return this._http.get<ListResumeParser>(url);
  }
  //get all resume
  getAllResume(url_dynamic: string): Observable<ResumeParser[]> {
    const url = `${this.BASE_URL}/resume/select_all?${url_dynamic}`;
    return this._http.get<ResumeParser[]>(url);
  }
  //get resume parser
  getFillListResume(id: number, page: number, page_size: number): Observable<ListResumeParser> {
    const url = `${this.BASE_URL}/resume/?task=${id}&page=${page + 1}&page_size=${page_size}`;
    return this._http.get<ListResumeParser>(url);
  }
  //get resume parser
  getFilterListResume(url_dynamic: string, page: number, page_size: number): Observable<ListResumeParser> {
    const url = `${this.BASE_URL}/resume/?page=${page + 1}&page_size=${page_size}` + `${url_dynamic}`;
    return this._http.get<ListResumeParser>(url);
  }
  //get task status
  getTaskStatus(id: number): Observable<ResultUpload> {
    const url = `${this.BASE_URL}/resume_parser/task/${id}`;
    return this._http.get<ResultUpload>(url).pipe(
      map((res) => {

        if (res.status == "Done") {
          this.statusSubject.next(res.status)
        }
        return res;
      })

    );
  }

  //Approve resume
  approveResume(id: number, formData: any): Observable<Status> {
    const url = `${this.BASE_URL}/resume/${id}/approve`;
    return this._http.put<Status>(url, formData).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            // "",
            // `Approved Success`,
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.STATUS.APPROVED"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Approve resume list
  approveListResume(task_id: number): Observable<Status> {
    const url = `${this.BASE_URL}/resume/approve`;
    return this._http.post<Status>(url, { task_id })
  }
  //Approve multi resume
  approveMultiResume(id: number[]): Observable<any>{
    const url = `${this.BASE_URL}/resume/approve-multi-resume`;
    return this._http.post<any>(url, {id})
    // .pipe(
    //   map((respData) => {
    //     setTimeout(() => {
    //       this._toastrService.success(
    //         this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.APPROVE_MUL_RESUME"),
    //         this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
    //         // "Success",
    //         // `Update resume`,
    //         {
    //           toastClass: "toast ngx-toastr",
    //           closeButton: true,
    //         }
    //       );
    //     }, 300);
    //     return respData;
    //   }),
    // )
  }
  //update resume
  updateResume(id: number, first_name: string, last_name: string, email: string, phone_number: string, gender: string, date_of_birth: any, address: string, category: string, year_exp: string, experience: any[], education: any[]): Observable<ResumeParser> {
    const url = `${this.BASE_URL}/resume/${id}`;
    return this._http.put<ResumeParser>(url, { first_name, last_name, email, phone_number, gender, date_of_birth, address, category, year_exp, experience, education }).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.EDIT_R_SUC"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Update resume`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //upload resume
  uploadResume(files: File[], source: string, talentpool: string, job_description: any): Observable<ResultUpload> {
    const uploadResumeData = new FormData();
    for (var i = 0; i < files.length; i++) {
      uploadResumeData.append("file", files[i]);
    }
    if(source){
      uploadResumeData.append("source",source)
    }
    if(talentpool){
      uploadResumeData.append("talent_pool",talentpool)
    }
    if(job_description){
      uploadResumeData.append("job_description",job_description)
    }
    const url = `${this.BASE_URL}/resume_parser/upload`;
    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');
    const httpOptions = {
      headers: headers
    }
    return this._http.post<ResultUpload>(url, uploadResumeData, httpOptions).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.TITLE"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Upload file`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //get detail resume
  getDetailResume(id: number): Observable<ResumeParser> {
    const url = `${this.BASE_URL}/resume/${id}`;

    return this._http.get<ResumeParser>(url);
  }
  //Get Censor resume
  getCensorResume(id: number): Observable<ResumeParser> {
    const url = `${this.BASE_URL}/resume/${id}/censor`;
    return this._http.get<ResumeParser>(url).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.CENSOR"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Censor`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Get DeCensor resume
  getDeCensorResume(id: number): Observable<ResumeParser> {
    const url = `${this.BASE_URL}/resume/${id}/decensor`;
    return this._http.get<ResumeParser>(url).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.DECENSOR"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `DeCensor`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //download resume
  // downloadResume(type_file: string): Observable<Blob>{
  //   const url = `${this.BASE_URL}/resume/export/?type=${type_file}`;
  //   return this._http.get(url, {responseType: 'blob'});
  // }

  //delete resume
  deleteResume(id: number): Observable<any> {
    const url = `${this.BASE_URL}/resume/${id}`;
    return this._http.delete<any>(url)
  }
  //delete education
  deleteEducation(id: number): Observable<any> {
    const url = `${this.BASE_URL}/background_edu/${id}`;
    return this._http.delete<any>(url)
  }
  //delete experience
  deleteExperience(id: number): Observable<any> {
    const url = `${this.BASE_URL}/background_exp/${id}`;
    return this._http.delete<any>(url)
  }
  //upload sf4c
  uploadSf4c(): Observable<Status> {
    const url = `${this.BASE_URL}/sourcing/sf4c_upload`;
    return this._http.post<Status>(url, {}).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            "",
            respData['message'],
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
      catchError((error) => {
        setTimeout(() => {
          console.log("ALO");
          this._toastrService.success(
            "",
            error.error['message'],
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return throwError(error);

      })
    );

  }

  //get credential sf4c connector
  getCredential(username: string, user: string): Observable<ResultConnectSf4c> {
    const url = `${this.BASE_URL}/organization/credentials`;
    const sourceData = {}
    sourceData["username"] = username
    sourceData["user"] = user
    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');
    const httpOptions = {
      headers: headers
    };
    return this._http.post<ResultConnectSf4c>(url, sourceData, httpOptions).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.CREATE_CREDENTIAL"),
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //get list credential
  getListCredential(): Observable<ResultConnectSf4c[]> {
    const url = `${this.BASE_URL}/organization/credentials`
    return this._http.get<ResultConnectSf4c[]>(url)
  }
  //get credential
  getDetailCredential(id: any): Observable<ResultConnectSf4c> {
    const url = `${this.BASE_URL}/organization/credentials/${id}`;
    return this._http.get<any>(url)
  }
  //update credential
  updateCredential(id: any, username: string, user: string): Observable<ResultConnectSf4c> {
    const url = `${this.BASE_URL}/organization/credentials/${id}`;
    const sourceData = {}
    sourceData["username"] = username
    sourceData["user"] = user

    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');
    const httpOptions = {
      headers: headers
    };
    return this._http.put<any>(url, sourceData, httpOptions).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.EDIT_CRED"),
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //delete credential
  deleteCredential(id): Observable<any> {
    const url = `${this.BASE_URL}/organization/credentials/delete/${id}`;
    return this._http.delete<any>(url).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.MODAL.DELETE_C"),
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Get Resume parser settings
  resumeSettings(): Observable<ResultResumeSettings> {
    const url = `${this.BASE_URL}/configure/setting`;
    return this._http.get<ResultResumeSettings>(url)
  }
  //Put Resume parser settings
  putResumeSettings(formData: any): Observable<ResultResumeSettings> {
    const url = `${this.BASE_URL}/configure/setting`;
    return this._http.put<ResultResumeSettings>(url, formData).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.AUTO_SETTING_CONFIG"),
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //sync email
  syncEmail(): Observable<Status> {
    const url = `${this.BASE_URL}/resume_parser/sync-email`;
    return this._http.get<Status>(url).pipe(
      map((res) => {
        console.log("res", res.message)
        setTimeout(
          () =>
            this._toastrService.success(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.SYNC_EMAIL"),
              "",
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            ),
          500
        );
        return res;
      })
    )
  }
  //get jr
  getJr(): Observable<Jr[]> {
    const url = `${this.BASE_URL}/job-description/job-requisition`;
    return this._http.get<Jr[]>(url)
  }
  //get jr with query
  getJrWithQuery(owner: number): Observable<Jr[]> {
    const url = `${this.BASE_URL}/job-description/?has_jr=true&owner=${owner}`;
    return this._http.get<Jr[]>(url)
  }
  //get jr detail
  getDetailJr(id: any): Observable<Jr> {
    const url = `${this.BASE_URL}/job-description/job-requisition/${id}`;
    return this._http.get<Jr>(url)
  }

  //getSf4cCandidate
  getSf4cCandidate(email: string): Observable<Sf4cCandidate> {
    const url = `${this.BASE_URL}/candidate/sf4c?email=${email}`;
    return this._http.get<Sf4cCandidate>(url)
  }
  //sf4c upload
  sf4c_upload(formData: any): Observable<Sf4cUpload> {
    const url = `${this.BASE_URL}/candidate/sf4c-upload`;
    return this._http.post<Sf4cUpload>(url, formData ).pipe(
      map((res) => {
        setTimeout(
          () =>
            this._toastrService.success(
              this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.UPLOAD_SAP_SF"),
              ``,
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            ),
          500
        );
        return res;
      })
    )
  }
  validUpload(resume_id: any[], jobReqId: any[]): Observable<any> {
    const url = `${this.BASE_URL}/candidate/check-valid-upload`;
    return this._http.post<any>(url, { resume_id, jobReqId })
  }
  validUploadJa(resume_id: any[], jobReqId: any[]): Observable<any> {
    const url = `${this.BASE_URL}/candidate/check-valid-upload-ja`;
    return this._http.post<any>(url, { resume_id, jobReqId })
  }
  //sf4c upload auto
  sf4c_auto_upload(task_id: number, jobReqId: any[]): Observable<Sf4cUpload> {
    const url = `${this.BASE_URL}/candidate/sf4c_auto_upload`;
    return this._http.post<Sf4cUpload>(url, { task_id, jobReqId })
  }
  //update jr
  updateJA(formData: any): Observable<Sf4cUpload> {
    const url = `${this.BASE_URL}/candidate/job-application`;
    return this._http.post<Sf4cUpload>(url, formData).pipe(
      map((res) => {
        console.log("res", res.job_application_status)
        setTimeout(
          () =>
            this._toastrService.success(
              this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.UPDATE_SAP_SF"),
              ``,
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            ),
          500
        );
        return res;
      })
    )
  }
  //update sf4c resume
  sf4c_sync(id: number, resume: any, sf4c_resume: any, jobReqId: number[]): Observable<Successfactor_update_status> {
    const url = `${this.BASE_URL}/candidate/sf4c/${id}/sf4c_sync`;
    return this._http.put<Successfactor_update_status>(url, { resume, sf4c_resume, jobReqId })
  }
  //Matching jr
  matching_jr(id: number, url_dynamic: string): Observable<Matching_JR[]> {
    const url = `${this.BASE_URL}/resume/${id}/job_matching` + `${url_dynamic}`;
    return this._http.get<Matching_JR[]>(url)
  }
  //Mapping picklist
  get_CHR_picklist(url_dynamic: string): Observable<CHR_Picklist[]> {
    const url = `${this.BASE_URL}/configure/picklist?type=` + `${url_dynamic}`;
    return this._http.get<CHR_Picklist[]>(url)
  }
  get_mapping_picklist(): Observable<Mapping_Picklist[]> {
    const url = `${this.BASE_URL}/configure/picklist/mapping`;
    return this._http.get<Mapping_Picklist[]>(url)
  }
  post_mapping_picklist(options_mapping_picklist: any[]): Observable<Mapping_Picklist> {
    const url = `${this.BASE_URL}/configure/picklist/mapping`;
    return this._http.post<Mapping_Picklist>(url, { options_mapping_picklist })
  }
  //Source mapping
  //Source mapping
  get_source_mapping(): Observable<ResultSourceMapping> {
    const url = `${this.BASE_URL}/configure/setting/mapping`;
    return this._http.get<ResultSourceMapping>(url)
  }
  //Update source mapping
  edit_source_mapping(id: number, config: any, jobapplication_config: any, company: number, autoupload_score: any, picklist_fields: any): Observable<ResultSourceMapping> {
    const url = `${this.BASE_URL}/configure/setting/mapping`;
    return this._http.put<ResultSourceMapping>(url, { id, config, jobapplication_config, company, autoupload_score, picklist_fields }).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.MAPPING_SOURCE"),
            this._translateService.instant("RESUME_PARSER.CONNECT_SF4C.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //get job application status
  getJobApplicationStatus(id: any): Observable<any> {
    const url = `${this.BASE_URL}/resume/${id}/ja_status`;
    return this._http.get<any>(url)
  }
  //download resume
  downloadResume(url_dynamic: any): Observable<any> {
    const url = `${this.BASE_URL}/resume/export/?${url_dynamic}`;
    return this._http.get(url, { responseType: 'blob' }).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Download`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  shareResume(id_resume: any, id_user: any[]): Observable<any> {
    const url = `${this.BASE_URL}/resume/${id_resume}/share`;
    const formData: any = {}
    formData["share_to"] = id_user
    return this._http.post<any>(url, formData).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.SHARE"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Share`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
      // catchError((err) => {
      //   this._toastrService.error(err.error.message, "", {
      //     toastClass: "toast ngx-toastr",
      //     closeButton: true,
      //   });
      //   return of(undefined);
      // })
    )
  }
  listSharedUser(id_resume: any) {
    const url = `${this.BASE_URL}/resume/${id_resume}/share`;
    return this._http.get<any>(url)
  }
  removeSharedUser(id_resume: any, id_user: any): Observable<any> {
    const url = `${this.BASE_URL}/resume/${id_resume}/share/${id_user}`;
    return this._http.delete<any>(url).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.DETAIL_RESUME.REMOVE_SHARE"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Remove share`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Talent pool
  listTalentPool(url_dynamic: string): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool?`+url_dynamic;
    return this._http.get<any>(url)
  }
  createTalentPool(formData: {}): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool`;
    return this._http.post<any>(url, formData).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.TALENT_POOL.CREATE"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Create talent pool`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  updateTalentPool(id:string, formData: {}): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.TALENT_POOL.EDIT"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Update talent pool`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  deleteTalentPool(id: string): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/${id}`;
    return this._http.delete<any>(url)
  }
  detailTalentPool(id: string): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/${id}`;
    return this._http.get<any>(url);
  }
  addResumes(formData: {}):Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/add_resume`;
    return this._http.post<any>(url, formData).pipe(
      // map((respData) => {
      //   setTimeout(() => {
      //     this._toastrService.success(
      //       this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.ADD_RESUME"),
      //       this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
      //       {
      //         toastClass: "toast ngx-toastr",
      //         closeButton: true,
      //       }
      //     );
      //   }, 300);
      //   return respData;
      // }),
    )
  }
  removeResumeInTalentPool(id: string,formData: {}): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/${id}/remove_resume`;
    return this._http.post<any>(url, formData)
  }
  changeTalentpoolResume(id: string, formData: {}): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/${id}/change_talent_pool`;
    return this._http.put<any>(url, formData)
  }
  removeResumes(formData: {}): Observable<any>{
    const url = `${this.BASE_URL}/resume/delete`;
    return this._http.post<any>(url, formData)
  }
  searchResumes(id:any, page: number, page_size: number, url_dynamic: string): Observable<any>{
    const url = `${this.BASE_URL}/resume/talentpool/${id}/search_resumes?page=${page+1}&page_size=${page_size}` + `${url_dynamic}`;;
    return this._http.get<any>(url)
  }
  sf4cPicklist(): Observable<any>{
    const url = `${this.BASE_URL}/candidate/sf4c-picklist`;
    return this._http.get<any>(url)
  }
  careersiteList(): Observable<any>{
    const url = `${this.BASE_URL}/resume_parser/career_site`;
    return this._http.get<any>(url)
  }
  //download stat
  downloadStat(url_dynamic: any): Observable<any> {
    const url = `${this.BASE_URL}/resume/export_stat/?${url_dynamic}`;
    return this._http.get(url, { responseType: 'blob' }).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_STAT"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            // "Success",
            // `Download`,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
}
