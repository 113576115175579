import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageResumeComponent } from './manage-resume/manage-resume.component';
import { IntegrationComponent } from './integration/integration.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from 'app/auth/helpers/jwt.interceptor';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { DetailResumeComponent } from './manage-resume/detail-resume/detail-resume.component';
import { ProgressBarAngularModule } from 'progress-bar-angular';
import { CachedSrcDirective } from './manage-resume/detail-resume/iframe.component';
import { EditResumeComponent } from './manage-resume/edit-resume/edit-resume.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MiscellaneousModule } from '../pages/miscellaneous/miscellaneous.module';
import { TalentPoolModule } from '../talent-pool/talent-pool.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { SharedUserComponent } from './manage-resume/detail-resume/shared-user/shared-user.component';
import { CoreSidebarModule } from '@core/components/core-sidebar/core-sidebar.module';
import { TalentPoolComponent } from './talent-pool/talent-pool.component';
import { DetailTalentPoolComponent } from './talent-pool/detail-talent-pool/detail-talent-pool.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { PhoneMaskDirective } from './manage-resume/edit-resume/phone-mask.directive';
const routes: Routes = [
  {
    path: "manage-resume",
    component: ManageResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },
  },
  {
    path: "manage-resume/detail-resume/:id",
    component: DetailResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },
  },
  { path: 'manage-job/:id/detail-resume/:id', component: DetailResumeComponent, canActivate: [AuthGuard],},

  { path: 'search-resume/detail-resume/:id', component: DetailResumeComponent, canActivate: [AuthGuard],},
    
  { path: 'manage-job/:id/recommend-resume/detail-resume/:id', component: DetailResumeComponent, canActivate: [AuthGuard]},
  { path: 'manage-job/:id/saved-resume/detail-resume/:id', component: DetailResumeComponent, canActivate: [AuthGuard]},
  
  {
    path: "integration/detail-resume/:id",
    component: DetailResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },

  },
  {
    path: "ja/detail-resume/:id",
    component: DetailResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },

  },
  {
    path: "manage-resume/edit-resume/:id",
    component: EditResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },

  },
  {
    path: "integration",
    component: IntegrationComponent,
    canActivate: [AuthGuard],
    data: { animation: "integration" },
  },
  {
    path: "integration/tab-ja",
    component: IntegrationComponent,
    canActivate: [AuthGuard],
    data: { animation: "integration" },
  },
  {
    path: "talent-pool",
    component: TalentPoolComponent,
    canActivate: [AuthGuard],
    data: { animation: "talent-pool" },
  },
  {
    path: "talent-pool/:id",
    component: DetailTalentPoolComponent,
    canActivate: [AuthGuard],
    data: { animation: "talent-pool" },
  },
  {
    path: "talent-pool/detail-resume/:id",
    component: DetailResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "talent-pool" },
  },
];

@NgModule({
  declarations: [
    ManageResumeComponent,
    IntegrationComponent,
    DetailResumeComponent,
    CachedSrcDirective,
    EditResumeComponent,
    SharedUserComponent,
    TalentPoolComponent,
    DetailTalentPoolComponent,
    PhoneMaskDirective
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    NgbNavModule,
    HttpClientModule,
    CoreCommonModule,
    NgbModule,
    ProgressBarAngularModule,
    BsDatepickerModule,
    FileUploadModule,
    NgxDocViewerModule,
    MiscellaneousModule,
    TalentPoolModule,
    NavbarModule,
    CoreSidebarModule,
    ContentHeaderModule

  ],
  exports: [CachedSrcDirective, PhoneMaskDirective],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
})
export class ResumeParserModule { }
