<p *ngIf="errorMessagesParseResume;else noError" [translate]="'RESUME_PARSER.DETAIL_RESUME.NOT_FOUND'"></p>
<ng-template #noError>
    <div class="content-wrapper container-xxxl p-0">
        <div class="content-body">
            <div class="d-flex" id="h-CV">
                <div class="" *ngIf="isLoading; else no_loading" id="loading" style="margin: 50px auto">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <ng-template #no_loading>
                    <div class="col-md-6">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs position-fixed" style="margin-top: -30px;z-index: 99;">
                            <li class="d-flex align-items-center">
                                <a class="text-primary" (click)="returnPage()">
                                    <span [size]="20" data-toggle="tooltip" data-placement="bottom"
                                        [ngbTooltip]="'RESUME_PARSER.DETAIL_RESUME.RETURN_PAGE' | translate"
                                        data-feather="arrow-left"></span>
                                </a>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="nav-link" id="nav-Resume-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-Resume" role="tab" aria-controls="nav-Resume"
                                    aria-selected="true" (click)="Tab()"
                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.TAB_CHR'"></a>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink
                                    *ngIf="tag_resultConnectSf4c_exit&&(resume_parser.sf4c_status=='SF4C_uploaded')"
                                    class="nav-link" id="nav-User-tab" data-bs-toggle="tab" data-bs-target="#nav-User"
                                    role="tab" aria-controls="nav-User" aria-selected="false" (click)="Tab_sf4c()"
                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.TAB_SF4C'"></a>
                            </li>
                        </ul>
                        <!-- <nav>
                            <div class="nav nav-tabs position-fixed d-flex justify-content-start" id="nav-tab" role="tablist" style="margin-top: -30px;">
                                <a class="nav-link active" id="nav-Resume-tab" data-bs-toggle="tab" data-bs-target="#nav-Resume" role="tab" aria-controls="nav-Resume" aria-selected="true" (click)="Tab()" [translate]="'RESUME_PARSER.DETAIL_RESUME.TAB_CHR'"></a>
                                <a *ngIf="tag_resultConnectSf4c_exit&&(resume_parser.sf4c_status=='SF4C_uploaded')" class="nav-link" id="nav-User-tab" data-bs-toggle="tab" data-bs-target="#nav-User" role="tab" aria-controls="nav-User" aria-selected="false" (click)="Tab_sf4c()" [translate]="'RESUME_PARSER.DETAIL_RESUME.TAB_SF4C'"></a>
                              </div>
                          </nav> -->
                        <div class="position-fixed" id="h-left" style="width: 38%;overflow-y: auto;overflow-x: hidden;">
                            <iframe id="iframe-CV" *ngIf="!tab_sf4c" [cachedSrc]="url"
                                style="width: 100%;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
                            <iframe *ngIf="pdf_content_sf4c && tab_sf4c" [cachedSrc]="url_sf4c"
                                style="width: 100%;height: 75vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
                            <div class="row mx-0 align-items-center">
                                <div class="col-3 p-0" *ngIf="!tab_sf4c && currentResumeIndex!=null">
                                    <nav aria-label="Page navigation example h-100">
                                        <ul class="pagination row m-0 h-100">
                                            <li class="page-item p-0">
                                                <button class="page-link h-100"
                                                    (click)="swapLink(currentResumeIndex - 1)">
                                                    <span data-feather="chevron-left" [size]="10"></span>
                                                </button>
                                            </li>

                                            <li
                                                class="page-item p-0 align-items-center d-flex flex-nowrap d-md-none d-lg-block text-center">
                                                <span style="padding: 3px">{{ currentResumeIndex + 1 }} /
                                                    {{ totalResume }}</span>
                                            </li>

                                            <li class="page-item p-0">
                                                <button class="page-link h-100"
                                                    (click)="swapLink(currentResumeIndex + 1)">
                                                    <span data-feather="chevron-right" [size]="10"></span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <!-- <button
                                    (click)="returnPage()"
                                    type="button"
                                    class="btn btn-primary rounded-pill"
                                    style="font-size: 14px;">
                                    <span [translate]="'RESUME_PARSER.DETAIL_RESUME.RETURN_PAGE'"></span>
                                    </button> -->
                                </div>
                                <div class="row col-9" *ngIf="!tab_sf4c">
                                    <div><button *ngIf="resume_parser.status=='Done'||resume_parser.status=='Modified'"
                                            (click)="ApproveResume()" style="margin-right: 4px;margin-top: 4px;"
                                            class="btn btn-success rounded-pill btn-sm" rippleEffect><span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.APPROVE'"></span></button>
                                    </div>
                                    <div>
                                        <button
                                            *ngIf="(resume_parser.status=='Approved'||resume_parser.status=='Done'||resume_parser.status=='Modified')"
                                            (click)="modalOpenAddTalentPool(modalAddTalentPool)"
                                            class="btn rounded-pill btn-primary btn-sm" rippleEffect
                                            style="margin-right: 4px;margin-top: 4px;"><span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.ADD_TALENT_POOL'"></span></button>
                                        <ng-template #modalAddTalentPool let-modal>
                                            <ng-modal class="modalAddResume">
                                                <modal-header class="modal-header">
                                                    <h4 class="modal-title"
                                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUME_TO_TALENTPOOL'">
                                                        Add resume to Talent pool
                                                    </h4>
                                                </modal-header>
                                                <modal-content>
                                                    <form class="form form-vertical"
                                                        [formGroup]="addResumeToTalentPoolForm">
                                                        <div class="modal-body flex-grow-1" tabindex="1"
                                                            style="overflow-y: visible;">
                                                            <div class="form-group">
                                                                <label class="form-label"
                                                                    for="basic-icon-default-fullname"><span
                                                                        [translate]="'RESUME_PARSER.TALENT_POOL.NAME_FOLDER'">Name
                                                                        talent pool</span><span
                                                                        style="color: red; padding-left: 5px">*</span></label>
                                                                <ng-select [searchable]="false" [items]="talentPools"
                                                                    bindLabel="name" bindValue="id"
                                                                    formControlName="talent_pool">
                                                                </ng-select>
                                                                <span *ngIf="
                                                      addResumeToTalentPoolForm.controls.talent_pool.invalid &&
                                                      addResumeToTalentPoolForm.controls.talent_pool.touched
                                                    " class="invalid-form">
                                                                    <small class="form-text text-danger"
                                                                        *ngIf="addResumeToTalentPoolForm.controls.talent_pool.errors.required"
                                                                        [translate]="'RESUME_PARSER.TALENT_POOL.REQUIRED'"></small>
                                                                </span>


                                                            </div>

                                                            <button tabindex="2" (click)="onAddResumeToTalentPool()"
                                                                [disabled]="!addResumeToTalentPoolForm.valid"
                                                                class="btn btn-primary mr-1">
                                                                <span
                                                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUMES'">Add
                                                                    resume to talent pool</span>
                                                            </button>
                                                            <button type="reset" class="btn btn-outline-secondary"
                                                                (click)="modal.dismiss('Cross click')" rippleEffect>
                                                                <span
                                                                    [translate]="'RESUME_PARSER.TALENT_POOL.CANCEL'">Cancel</span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </modal-content>
                                            </ng-modal>
                                        </ng-template>
                                    </div>
                                    <div>
                                        <button
                                            *ngIf="!display_decensor && (resume_parser.status=='Approved'||resume_parser.status=='Done'||resume_parser.status=='Modified')"
                                            (click)="CensorResume()" class="btn rounded-pill btn-warning btn-sm"
                                            rippleEffect style="margin-right: 4px;margin-top: 4px;"><span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.CENSOR'"></span></button>
                                    </div>

                                    <div>
                                        <button
                                            *ngIf="display_decensor && (resume_parser.status=='Approved'||resume_parser.status=='Done'||resume_parser.status=='Modified')"
                                            (click)="DeCensorResume()"
                                            style="background-color: #E4572E;margin-right: 4px;margin-top: 4px;"
                                            class="btn rounded-pill text-light btn-sm" rippleEffect> <span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DECENSOR'"></span></button>
                                    </div>
                                    <div>
                                        <button
                                            *ngIf="(resume_parser.status=='Approved'||resume_parser.status=='Done'||resume_parser.status=='Modified')"
                                            (click)="modalOpenSaveCv(modalBasicSaveCv)"
                                            style="background-color: #688B58;margin-right: 4px;margin-top: 4px;"
                                            class="btn rounded-pill text-light btn-sm" rippleEffect> <span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT'"></span></button>
                                        <ng-template #modalBasicSaveCv let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title"
                                                    [translate]="'TALENT_POOL.SEARCH_RESUME.SELECT_JD'"></h4>
                                                <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button> -->
                                            </div>
                                            <div class="me-4 mt-1">
                                                <div class="d-flex justify-content-end">

                                                    <div class="col-md-6">
                                                        <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                                        <input type="text" class="form-control" id="job-search"
                                                            [placeholder]="'TALENT_POOL.SEARCH_RESUME.SEARCH_JOB' | translate"
                                                            [(ngModel)]="searchText" (input)="SearchJr()" />
                                                    </div>

                                                </div>
                                            </div>
                                            <form [formGroup]="formCheckBoxSaveCv">
                                                <div class="modal-body" ngbAutofocus>
                                                    <!-- <ng-container *ngIf="list_all_jd.length===0">
                                                                <p [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_JOB'"> <a routerLink="/manage-job/create-job" [translate]="'TALENT_POOL.SEARCH_RESUME.CREATE_JOB'"></a> </p>
                                                            </ng-container> -->
                                                    <ng-container>
                                                        <table class="table align-middle table-bordered"
                                                            style="height: 300px;">
                                                            <thead>
                                                                <tr>
                                                                    <th class="pxp-is-checkbox" style="width: 1%;"></th>
                                                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.JOB'"></th>
                                                                    <th
                                                                        [translate]="'TALENT_POOL.MANAGE_JOB.JR_ID_CHR'">
                                                                    </th>
                                                                    <!-- <th [translate]="'TALENT_POOL.MANAGE_JOB.CATEGORY'"></th> -->
                                                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.TYPE'">
                                                                    </th>
                                                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.LEVEL'">
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let list_all_jd_ of list_all_jd| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                                                    <td
                                                                        *ngIf="arr_checkboxSaveCv==list_all_jd_.id;else noChecked">
                                                                        <input [value]="list_all_jd_.id"
                                                                            (change)="onCheckboxChangeSaveCv($event)"
                                                                            type="checkbox" class="form-check" checked>

                                                                    </td>
                                                                    <ng-template #noChecked>
                                                                        <td>
                                                                            <input [value]="list_all_jd_.id"
                                                                                (change)="onCheckboxChangeSaveCv($event)"
                                                                                type="checkbox" class="form-check">

                                                                        </td>
                                                                    </ng-template>


                                                                    <td>
                                                                        <div class="">{{list_all_jd_.job_title}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="">{{list_all_jd_.id}}
                                                                        </div>
                                                                    </td>
                                                                    <!-- <td>
                                                                                <div class="">{{list_all_jd_.industry}}</div>
                                                                            </td> -->
                                                                    <td>
                                                                        <div class="">{{list_all_jd_.job_type}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="">{{list_all_jd_.job_level}}</div>
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div class="row justify-content-between align-items-center">
                                                            <div class="col-auto">

                                                                <nav class="mt-sm-0" aria-label="Jobs list pagination">

                                                                    <ngb-pagination
                                                                        [collectionSize]="list_all_jd.length"
                                                                        [(page)]="page" [pageSize]="pageSize"
                                                                        [maxSize]="10" [rotate]="true"
                                                                        [boundaryLinks]="true">


                                                                    </ngb-pagination>
                                                                </nav>
                                                            </div>

                                                        </div>
                                                    </ng-container>

                                                </div>

                                                <div class="modal-footer">

                                                    <button [disabled]="!arr_checkboxSaveCv" (click)="FormCheckBoxSaveCv()" class="btn btn-primary mr-1"
                                                        rippleEffect [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE'">

                                                    </button>
                                                    <button (click)="modal.dismiss('Cross click')" type="reset"
                                                        rippleEffect class="btn btn-secondary"
                                                        [translate]="'TALENT_POOL.CREATE_JOB.CANCEL'"></button>

                                                </div>
                                            </form>
                                        </ng-template>
                                    </div>
                                    <div
                                        *ngIf="((resume_parser.status=='Approved' || resume_parser.sf4c_status=='SF4C_failed') && resume_parser.sf4c_status!=='SF4C_uploaded') && tag_resultConnectSf4c_exit">
                                        <button (click)="modalOpenUploadSf4c(modalBasic)"
                                            style="margin-right: 4px;margin-top: 4px;"
                                            class="btn btn-info rounded-pill btn-sm" rippleEffect><span
                                                [translate]="resume_parser.sf4c_status=='SF4C_uploaded'?'RESUME_PARSER.INTEGRATION.UPLOAD_JA'
                                                
                                                :resume_parser.sf4c_status=='SF4C_failed'?'RESUME_PARSER.PARSE_RESUME.REUPLOAD':'RESUME_PARSER.PARSE_RESUME.UPLOAD_SF4C'"></span></button>

                                    </div>
                                    <div
                                        *ngIf="(resume_parser.sf4c_status=='SF4C_uploaded') && tag_resultConnectSf4c_exit">
                                        <button (click)="modalOpenUploadSf4c(modalBasicJa, true)"
                                            style="margin-right: 4px;margin-top: 4px;"
                                            class="btn btn-info rounded-pill btn-sm" rippleEffect><span
                                                [translate]="resume_parser.sf4c_status=='SF4C_uploaded'?'RESUME_PARSER.INTEGRATION.UPLOAD_JA'
                                                
                                                :resume_parser.sf4c_status=='SF4C_failed'?'RESUME_PARSER.PARSE_RESUME.REUPLOAD':'RESUME_PARSER.PARSE_RESUME.UPLOAD_SF4C'"></span></button>
                                        <ng-template #modalBasicJa let-modal>

                                            <div class="modal-header">
                                                <h4 class="modal-title"
                                                    [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
                                                <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                              </button> -->
                                            </div>
                                            <div class="form-group" style="margin:1.3rem">
                                                <label class="form-label" for="basic-icon-default-fullname"><span
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></span></label>
                                            </div>
                                            <div class="me-4">
                                                <div class="d-flex justify-content-end">

                                                    <div class="input-group col-md-6">
                                                        <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                                        <input type="text" class="form-control" id="job-search"
                                                            [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SEARCH' | translate"
                                                            aria-label="Search..." aria-describedby="job-search"
                                                            [(ngModel)]="searchText" (input)="Search()" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <form [formGroup]="formCheckBoxValid">
                                                <div class="modal-body" ngbAutofocus>


                                                    <table class="table align-middle table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 1%;"></th>
                                                                <th
                                                                    [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'">
                                                                </th>
                                                                <th
                                                                    [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'">
                                                                </th>



                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let resultJr_ of resultJr
                                                                                | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                                                                <td
                                                                    *ngIf="arr_checkboxJr.includes(resultJr_.hcm_system_jd);else noChecked">
                                                                    <input [value]="resultJr_.hcm_system_jd"
                                                                        (change)="onCheckboxChangeJr($event)"
                                                                        type="checkbox" class="form-check" checked>

                                                                </td>
                                                                <ng-template #noChecked>
                                                                    <td>
                                                                        <input [value]="resultJr_.hcm_system_jd"
                                                                            (change)="onCheckboxChangeJr($event)"
                                                                            type="checkbox" class="form-check">

                                                                    </td>
                                                                </ng-template>

                                                                <td>
                                                                    <div class="">{{resultJr_.hcm_system_jd}}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="">{{resultJr_.job_title}}</div>
                                                                </td>


                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <div class="row mt-1 mt-lg-1 justify-content-end align-items-center"
                                                        *ngIf="resultJr.length>0">
                                                        <div class="col-auto">

                                                            <nav class="mt-1 mt-sm-0" aria-label="Jobs list pagination">

                                                                <ngb-pagination [collectionSize]="resultJr.length"
                                                                    [(page)]="pageJr" [pageSize]="basicSelectedOption"
                                                                    [maxSize]="10" [rotate]="true"
                                                                    [boundaryLinks]="true">


                                                                </ngb-pagination>
                                                            </nav>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="modal-footer">

                                                    <button *ngIf="loading_;else noLoadingUpload"
                                                        (click)="FormCheckValidUpload(modalCheckValid, true)"
                                                        class="btn btn-primary ms-3 mr-1" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1"
                                                            role="status" aria-hidden="true"></span>
                                                        <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                                    </button>
                                                    <ng-template #noLoadingUpload>
                                                        <button [disabled]="arr_checkboxJr?.length==0"
                                                            *ngIf="resultCheckValidUpload?.invalid_job_app_id?.length>0;else noArr"
                                                            class="btn btn-primary ms-3 mr-1" rippleEffect type="button"
                                                            (click)="FormCheckValidUpload(modalCheckValid, true)"
                                                            (click)="modal.dismiss('Cross click')">
                                                            <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                                        </button>
                                                        <ng-template #noArr>
                                                            <button [disabled]="arr_checkboxJr?.length==0"
                                                                class="btn btn-primary ms-3 mr-1" rippleEffect
                                                                type="button"
                                                                (click)="FormCheckValidUpload(modalCheckValid, true)">
                                                                <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                                            </button>
                                                        </ng-template>
                                                    </ng-template>
                                                    <button (click)="modal.dismiss('Cross click')"
                                                        (click)="cancelFormCheckboxValid()" type="reset" rippleEffect
                                                        class="btn btn-secondary"
                                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>

                                                </div>
                                            </form>
                                        </ng-template>
                                        <ng-template #modalCheckValid let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title"
                                                    [translate]="'RESUME_PARSER.INTEGRATION.TITLE_PROCESS'"></h4>
                                            </div>
                                            <form [formGroup]="formCheckBoxJr">
                                                <div class="modal-body" ngbAutofocus>
                                                    <table class="table align-middle table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th [translate]="'RESUME_PARSER.INTEGRATION.RESUME_ID'">
                                                                </th>
                                                                <th
                                                                    [translate]="'RESUME_PARSER.INTEGRATION.JOB_REQ_ID'">
                                                                </th>
                                                                <th [translate]="'RESUME_PARSER.INTEGRATION.ERROR'">
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let _resultCheckValidUpload of resultCheckValidUpload?.invalid_job_app_id
                                                                                | slice: (pageCheckValid - 1) * basicSelectedOption:(pageCheckValid - 1) * basicSelectedOption + basicSelectedOption">
                                                                <td>
                                                                    <div class="">{{_resultCheckValidUpload.id}}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="">{{_resultCheckValidUpload.job_id}}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div *ngIf="language=='en';else VN" class="">
                                                                        {{_resultCheckValidUpload.error.en}}</div>
                                                                    <ng-template #VN>
                                                                        <div class="">
                                                                            {{_resultCheckValidUpload.error.vn}}</div>

                                                                    </ng-template>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="row mt-1 mt-lg-1 justify-content-between align-items-center"
                                                        *ngIf="resultCheckValidUpload?.invalid_job_app_id.length>0">
                                                        <div class="col-auto">
                                                            <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                                                                <ngb-pagination
                                                                    [collectionSize]="resultCheckValidUpload?.invalid_job_app_id.length"
                                                                    [(page)]="pageCheckValid"
                                                                    [pageSize]="basicSelectedOption" [maxSize]="10"
                                                                    [rotate]="true" [boundaryLinks]="true">
                                                                </ngb-pagination>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">

                                                    <button *ngIf="loadingFormJA;else noLoadingUploadJA"
                                                        (click)="FormCheckBoxJA()" class="btn btn-primary ms-3 mr-1"
                                                        type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1"
                                                            role="status" aria-hidden="true"></span>
                                                        <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                                                    </button>
                                                    <ng-template #noLoadingUploadJA>
                                                        <button class="btn btn-primary ms-3 mr-1" rippleEffect
                                                            (click)="FormCheckBoxJA()">
                                                            <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                                                        </button>
                                                    </ng-template>
                                                    <button (click)="modal.dismiss('Cross click')"
                                                        (click)="cancelFormCheckBoxJr()" type="reset" rippleEffect
                                                        class="btn btn-secondary"
                                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
                                                </div>
                                            </form>
                                        </ng-template>

                                    </div>

                                    <!-- <button (click)="matchingJrInit()" style="font-size: 14px;" class="btn btn-primary rounded-pill mr-1"rippleEffect><span [translate]="'RESUME_PARSER.DETAIL_RESUME.JOB_REQ_MATCHING'"></span></button> -->

                                    <ng-template #modalBasic let-modal>



                                        <div class="modal-header">
                                            <h4 class="modal-title"
                                                [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'">
                                            </h4>


                                        </div>
                                        <div class="form-group" style="margin:1.3rem">
                                            <label class="form-label" for="basic-icon-default-fullname"><span
                                                    [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></span></label>
                                        </div>
                                        <div class="me-4">
                                            <div class="d-flex justify-content-end">

                                                <div class="input-group col-md-6">
                                                    <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                                    <input type="text" class="form-control" id="job-search"
                                                        [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SEARCH' | translate"
                                                        aria-label="Search..." aria-describedby="job-search"
                                                        [(ngModel)]="searchText" (input)="Search()" />
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <div *ngIf="loading" id="loading" style="margin: 50px auto">
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <!-- <app-loading *ngIf="loading"></app-loading> -->
                                        </div>
                                        <form [formGroup]="formCheckBoxValid">
                                            <div class="modal-body" ngbAutofocus style="overflow-y: visible;">


                                                <table class="table align-middle table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 1%;"></th>
                                                            <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'">
                                                            </th>
                                                            <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'">
                                                            </th>

                                                            <!-- <th>Score</th> -->

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="have_Jr && !loading;else noJr">
                                                            <tr
                                                                *ngFor="let resultJr_ of resultJr
                                                                    | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                                                                <td
                                                                    *ngIf="arr_checkboxJr.includes(resultJr_.hcm_system_jd);else noChecked">
                                                                    <input [value]="resultJr_.hcm_system_jd"
                                                                        (change)="onCheckboxChangeJr($event)"
                                                                        type="checkbox" class="form-check" checked>

                                                                </td>
                                                                <ng-template #noChecked>
                                                                    <td>
                                                                        <input [value]="resultJr_.hcm_system_jd"
                                                                            (change)="onCheckboxChangeJr($event)"
                                                                            type="checkbox" class="form-check">

                                                                    </td>
                                                                </ng-template>

                                                                <td>
                                                                    <div class="">{{resultJr_.hcm_system_jd}}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="">{{resultJr_.job_title}}</div>
                                                                </td>
                                                                <!-- <td>
                                                                            <div class="" >{{resultJr_.score*100|number:'1.2-2'}}%</div>
                                                                        </td> -->

                                                            </tr>
                                                        </ng-container>
                                                        <!-- <ng-template #noJr>
                                                            <tr>
                                                                <p *ngIf="!loading">
                                                                    NoSuitableJr
                                                                </p>
                                                            </tr>
                                                        </ng-template> -->

                                                    </tbody>
                                                </table>
                                                <div class="row mt-1 mt-lg-1 justify-content-end align-items-center"
                                                    *ngIf="resultJr?.length>0">
                                                    <div class="col-auto">

                                                        <nav class="mt-1 mt-sm-0" aria-label="Jobs list pagination">

                                                            <ngb-pagination [collectionSize]="resultJr?.length"
                                                                [(page)]="pageJr" [pageSize]="basicSelectedOption"
                                                                [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                                                            </ngb-pagination>
                                                        </nav>
                                                    </div>

                                                </div>
                                                <div class="form-group"
                                                    *ngIf="sf4cPicklist?.importedby?.length>0 && (user.role=='Super admin' || user.role=='Admin')">
                                                    <label class="form-label" for="basic-icon-default-fullname"><span
                                                            [translate]="'RESUME_PARSER.INTEGRATION.IMPORTEDBY'">Imported
                                                            by</span></label>
                                                    <ng-select type="text" id="importedby"
                                                        [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SELECTED_IMPORTEDBY'|translate"
                                                        formControlName="importedby">
                                                        <ng-option *ngFor="let importedby of sf4cPicklist.importedby"
                                                            [value]="importedby">{{importedby.value}}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div *ngIf="formCheckBoxValid.value.importedby">
                                                    <label
                                                        [translate]="'RESUME_PARSER.INTEGRATION.SOURCE'"></label><span>:
                                                    </span><span>{{formCheckBoxValid.value?.importedby?.source_value}}</span>
                                                </div>
                                            </div>
                                            <div class="modal-footer">

                                                <button *ngIf="loading_;else noLoadingUpload"
                                                    (click)="FormCheckValidUpload(modalCheckValid)"
                                                    class="btn btn-primary ms-3 mr-1" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm mr-1" role="status"
                                                        aria-hidden="true"></span>
                                                    <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                                </button>
                                                <ng-template #noLoadingUpload>
                                                    <button
                                                        *ngIf="resultCheckValidUpload?.invalid_resume_id?.length>0;else noArr"
                                                        class="btn btn-primary ms-3 mr-1" rippleEffect type="button"
                                                        (click)="FormCheckValidUpload(modalCheckValid)">
                                                        <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                                    </button>
                                                    <ng-template #noArr>
                                                        <button class="btn btn-primary ms-3 mr-1" rippleEffect
                                                            type="button"
                                                            (click)="FormCheckValidUpload(modalCheckValid)">
                                                            <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                                        </button>
                                                    </ng-template>
                                                </ng-template>
                                                <button (click)="modal.dismiss('Cross click')"
                                                    (click)="cancelFormCheckboxValid()" type="reset" rippleEffect
                                                    class="btn btn-secondary"
                                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
                                            </div>

                                        </form>
                                    </ng-template>

                                    <ng-template #modalCheckValid let-modal>

                                        <div class="modal-header">
                                            <h4 class="modal-title"
                                                [translate]="'RESUME_PARSER.INTEGRATION.TITLE_PROCESS'">
                                            </h4>

                                        </div>
                                        <form [formGroup]="formCheckBoxJr">
                                            <div class="modal-body" ngbAutofocus>


                                                <table class="table align-middle table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th [translate]="'RESUME_PARSER.INTEGRATION.OVERWRITE'">
                                                            </th>
                                                            <th [translate]="'RESUME_PARSER.INTEGRATION.RESUME_ID'">
                                                            </th>
                                                            <th [translate]="'RESUME_PARSER.INTEGRATION.JOB_REQ_ID'">
                                                            </th>
                                                            <th [translate]="'RESUME_PARSER.INTEGRATION.ERROR'"></th>

                                                            <!-- <th>Score</th> -->

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let _resultCheckValidUpload of resultCheckValidUpload?.invalid_resume_id
                                                                      | slice: (pageCheckValid - 1) * basicSelectedOption:(pageCheckValid - 1) * basicSelectedOption + basicSelectedOption">
                                                            <td
                                                                *ngIf="!_resultCheckValidUpload.overwrite;else Overwrite">
                                                                <input [value]="_resultCheckValidUpload.id"
                                                                    (change)="onCheckboxChangeValid($event)"
                                                                    type="checkbox" class="form-check" disabled>
                                                            </td>
                                                            <ng-template #Overwrite>
                                                                <td
                                                                    *ngIf="arr_checkboxValid.includes(_resultCheckValidUpload.id);else noChecked">
                                                                    <input [value]="_resultCheckValidUpload.id"
                                                                        (change)="onCheckboxChangeValid($event)"
                                                                        type="checkbox" class="form-check" checked>

                                                                </td>
                                                                <ng-template #noChecked>
                                                                    <td>
                                                                        <input [value]="_resultCheckValidUpload.id"
                                                                            (change)="onCheckboxChangeValid($event)"
                                                                            type="checkbox" class="form-check">

                                                                    </td>
                                                                </ng-template>
                                                            </ng-template>

                                                            <td>
                                                                <div class="">{{_resultCheckValidUpload.id}}</div>
                                                            </td>
                                                            <td>
                                                                <div class="">{{_resultCheckValidUpload.job_id}}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div *ngIf="language=='en';else VN" class="">
                                                                    {{_resultCheckValidUpload.error.en}}</div>
                                                                <ng-template #VN>
                                                                    <div class="">{{_resultCheckValidUpload.error.vn}}
                                                                    </div>

                                                                </ng-template>
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div class="row mt-4 mt-lg-5 justify-content-between align-items-center"
                                                    *ngIf="resultCheckValidUpload?.invalid_resume_id.length>0">
                                                    <div class="col-auto">

                                                        <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                                                            <ngb-pagination
                                                                [collectionSize]="resultCheckValidUpload?.invalid_resume_id.length"
                                                                [(page)]="pageCheckValid"
                                                                [pageSize]="basicSelectedOption" [maxSize]="10"
                                                                [rotate]="true" [boundaryLinks]="true">


                                                            </ngb-pagination>
                                                        </nav>
                                                    </div>

                                                </div>
                                                <!-- <div>
                                                    <b style="color: red;">*</b>: <span [translate]="'RESUME_PARSER.INTEGRATION.ASKED'"></span>
                                                </div> -->
                                            </div>
                                            <div class="modal-footer">

                                                <button *ngIf="loading_;else noLoadingUploadJA"
                                                    (click)="FormCheckBoxJr()" class="btn btn-primary ms-3 mr-1"
                                                    type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm mr-1" role="status"
                                                        aria-hidden="true"></span>
                                                    <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                                                </button>
                                                <ng-template #noLoadingUploadJA>
                                                    <button
                                                        [disabled]="arr_checkboxValid.length==0 && resultCheckValidUpload.resume_id.length==0"
                                                        class="btn btn-primary ms-3 mr-1" rippleEffect
                                                        (click)="FormCheckBoxJr()">
                                                        <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                                                    </button>
                                                </ng-template>
                                                <button (click)="modal.dismiss('Cross click')" type="reset"
                                                    (click)="cancelFormCheckBoxJr()" rippleEffect
                                                    class="btn btn-secondary"
                                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
                                            </div>
                                        </form>
                                    </ng-template>
                                    <div>
                                        <button
                                            *ngIf="(resume_parser.status=='Approved'||resume_parser.status=='Done'||resume_parser.status=='Modified')"
                                            (click)="modalOpenShareUser(modalBasicShareUser)"
                                            style="background-color: #7367f0;margin-top: 4px;"
                                            class="btn rounded-pill text-light btn-sm" rippleEffect> <span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.SHARE'">Share
                                                user</span></button>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                </ng-template>

                <div class="col-md-6" *ngIf="tag_detail && !tab_sf4c">
                    <div>

                        <div class="" style="padding-top: 10px;">
                            <div class="d-flex justify-content-start align-items-center">
                                <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE_INFORMATION'"></h1>
                                <a *ngIf="!user.is_superuser"
                                    routerLink="/manage-resume/edit-resume/{{resume_parser.id}}"
                                    style="font-size: 14px;margin-bottom: 26px;color: darkorange;">
                                    <span style="margin-left: 4px;" data-toggle="tooltip" data-placement="bottom"
                                        [ngbTooltip]="'RESUME_PARSER.DETAIL_RESUME.EDIT' | translate"
                                        data-feather="edit"></span>
                                </a>

                                <span *ngIf="resume_parser.sf4c_status!='Not_upload';else noStatusSf4c"
                                    title="{{resume_parser.sf4c_message}}"
                                    class="badge bg-{{status_color[resume_parser.sf4c_status]}}"
                                    style="font-size: 15px;margin: 15px;">
                                    <small *ngIf="resume_parser.sf4c_status.replaceAll('_',' ')=='SF4C wait to upload'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_WAIT'"></small>
                                    <small *ngIf="resume_parser.sf4c_status.replaceAll('_',' ')=='SF4C failed'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_FAILED'"></small>
                                    <small *ngIf="resume_parser.sf4c_status.replaceAll('_',' ')=='SF4C uploading'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADING'"></small>
                                    <small *ngIf="resume_parser.sf4c_status.replaceAll('_',' ')=='SF4C uploaded'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADED'"></small>
                                    <small *ngIf="resume_parser.sf4c_status.replaceAll('_',' ')=='SF4C not upload'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_NOT_UPLOAD'"></small>

                                </span>
                                <ng-template #noStatusSf4c>
                                    <span class="badge bg-{{status_color[resume_parser.status]}}"
                                        style="font-size: 15px;margin: 15px;">
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Approved'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.APPROVED'"></small>
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Duplicated'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DUPLICATED'"></small>    
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Done'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DONE'"></small>
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Failure'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.FAILURE'"></small>
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Processing'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.PROCESSING'"></small>
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Wait to process'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.WAIT_TO_PROCESS'"></small>
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Suspended'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.SUSPENDED'"></small>
                                        <small *ngIf="resume_parser.status.replaceAll('_',' ')=='Modified'"
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.MODIFIED'"></small>

                                    </span>

                                </ng-template>
                            </div>
                            <div class="row mx-0">
                                <div class="col-12 col-md-6">
                                    <p *ngIf="user.role!='Free trial user'"><span class="font-weight-bolder"
                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.RESUME_ID'"></span>
                                    {{resume_parser.id}}</p>
                                    <p *ngIf="user.role!='Free trial user'"><span class="font-weight-bolder"
                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE'"></span>
                                        {{resume_parser.candidate}}</p>

                                    <p
                                        *ngIf="resume_parser.successfactor_candidate_id!=null && user.role!='Free trial user'">
                                        <span class="font-weight-bolder"
                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.SF4C_RESUME_ID'"></span>
                                        {{resume_parser.successfactor_candidate_id}}
                                    </p>
                                    <p *ngIf="resume_parser.last_modified_by"><span
                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.LAST_MODIFIED_BY'"
                                            class="font-weight-bolder"></span><span>: </span>
                                        {{resume_parser.last_modified_by}}</p>
                                </div>
                                <div class="col-12 col-md-6">
                                    <p *ngIf="resume_parser.source"><span
                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.SOURCE'"
                                            class="font-weight-bolder"></span><span>: </span> {{resume_parser.source}}
                                    </p>

                                    <p><span [translate]="'RESUME_PARSER.PARSE_RESUME.MODIFIED_AT'"
                                            class="font-weight-bolder"></span><span>: </span>
                                        {{resume_parser.modified_at|date:'d/M/yyyy, h:mm a'}}</p>
                                    <p *ngIf="user.email!=listShared[0]?.share_by && listShared.length>0"><span
                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.SHARE_BY'"
                                            class="font-weight-bolder"></span><span>: </span>
                                        {{listShared[0]?.share_by}}</p>
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <span *ngIf="listShared.length>0"><span
                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.SHARED'"
                                                    class="font-weight-bolder"></span><span>: </span></span>
                                        </div>

                                        <div class="avatar-group">
                                            <div *ngFor="let user of listShared.slice(0,3)" data-toggle="tooltip"
                                                data-popup="tooltip-custom" placement="top" container="body" title=""
                                                class="avatar pull-up my-0" [ngbTooltip]="user.share_to">
                                                <app-avatar [name]="user.share_to"></app-avatar>
                                            </div>
                                            <h6 *ngIf="listShared.length>3"
                                                class="align-self-center cursor-pointer ml-50 mb-0">
                                                +{{listShared.length-3}}</h6>

                                            <a *ngIf="listShared.length>0" href="javascript:void(0);"
                                                (click)="toggleSidebar('shared-user')"
                                                class="font-weight-bold align-self-center cursor-pointer ml-50 mb-0"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.READ_MORE'">Read more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <p *ngIf="sf4cCandidate.candidateId!=null && resume_parser.sf4c_status=='SF4C_failed'" [translate]="'RESUME_PARSER.DETAIL_RESUME.SF4C_RESUME_ID'"> {{sf4cCandidate.candidateId}}</p> -->


                            <div id="accordionExample" style="height: calc(100vh - 220px);overflow-y: scroll">
                                <div class="card">
                                    <div class="" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">

                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span>:
                                                    {{resume_parser.first_name}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span>:
                                                    {{resume_parser.last_name}}</p>
                                            </div>

                                            <div *ngIf="resume_parser.date_of_birth;else noDob">
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.DOB'"></span>:
                                                    {{resume_parser.date_of_birth|date:'d/M/yyyy'}}</p>
                                            </div>
                                            <ng-template #noDob>
                                                <div>
                                                    <p><span class="font-weight-bolder"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.ESTIMATE_DOB'"></span>:
                                                        {{resume_parser.estimate_dob|date:'d/M/yyyy'}}</p>
                                                </div>
                                            </ng-template>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span>: <span
                                                        *ngIf="resume_parser.gender=='Female'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.GENDER_.FEMALE'"></span><span
                                                        *ngIf="resume_parser.gender=='Male'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.GENDER_.MALE'"></span>
                                                </p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span>:
                                                    {{resume_parser.address}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span>:
                                                    {{resume_parser.email}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span>:
                                                    {{resume_parser.phone_number}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY'"></span>:

                                                    <span
                                                        *ngIf="resume_parser.category!='UNKNOWN'">{{resume_parser.category}}</span>

                                                </p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXPERIENCE'"></span>:
                                                    <span
                                                        *ngIf="resume_parser.year_exp=='Under 1 year' && resume_parser.year_exp!='UNKNOWN'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.UNDER_YEAR'"></span>
                                                    <span
                                                        *ngIf="resume_parser.year_exp=='1 - 3 years' && resume_parser.year_exp!='UNKNOWN'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.THREE_YEAR'"></span>
                                                    <span
                                                        *ngIf="resume_parser.year_exp=='3 - 5 years' && resume_parser.year_exp!='UNKNOWN'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.FIVE_YEAR'"></span>
                                                    <span
                                                        *ngIf="resume_parser.year_exp=='5 - 10 years' && resume_parser.year_exp!='UNKNOWN'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.TEN_YEAR'"></span>
                                                    <span
                                                        *ngIf="resume_parser.year_exp=='Over 10 years' && resume_parser.year_exp!='UNKNOWN'"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.OVER_TEN_YEAR'"></span>

                                                </p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.TOTAL_YEAR_EXP'"></span>:
                                                    <span *ngIf="resume_parser?.total_year_exp"><span>{{resume_parser?.total_year_exp?.years}}
                                                        </span> <span
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR'"></span>
                                                        {{resume_parser?.total_year_exp?.months}} <span
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.MONTH'"></span></span>


                                                </p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'"></span>:
                                                    {{resume_parser.current_title}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'"></span>:
                                                    {{resume_parser.current_company}}</p>
                                            </div>
                                            <div *ngIf="resume_parser.skills.length>0">
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.SKILLS'"></span><span>:</span>
                                                </p>
                                                <div *ngFor="let skill of resume_parser.skills" class="list-inline"
                                                    style="display: inline-block">
                                                    <span class="badge bg-primary"
                                                        style="margin-left: 2px;">{{skill}}</span>
                                                </div>
                                            </div>

                                            <br>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'"></span><span>:
                                                    </span><span class="badge" [ngClass]="{
                                            'badge-success':resume_parser.conf_score>range_conf.success,
                                            'badge-warning':resume_parser.conf_score>range_conf.warning && resume_parser.conf_score < range_conf.success,
                                            'badge-danger':resume_parser.conf_score<range_conf.warning
                                        }">{{resume_parser.conf_score*100|number:'1.2-2'}}%</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="" *ngFor="let experience of resume_parser.experience">
                                                <div class="main" [ngClass]="{'border border-success':experience.conf_score>range_conf.success,'border border-warning':
                                                experience.conf_score>range_conf.warning && experience.conf_score < range_conf.success, 'border border-danger':
                                                experience.conf_score<range_conf.warning}"
                                                    style="border-style: dashed !important;">
                                                    <div class=""></div>
                                                    <div class="ms-3">
                                                        <div><span class="text-dark"
                                                                data-feather="calendar"></span><span
                                                                style="width:fit-content">
                                                                {{experience.start_time|date:'d/M/yyyy'}} <span
                                                                    *ngIf="experience.start_time && experience.end_time">
                                                                    - </span>
                                                                {{experience.end_time|date:'d/M/yyyy'}}</span></div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span>:
                                                                {{experience.position}}</span></div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span>:
                                                            {{experience.company}}</div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.INDUSTRY'"></span>:
                                                            {{experience.industry}}</div>

                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DESCRIPTION_JOB'"></span>:
                                                        </div>
                                                        <div class="ml-1" *ngIf="experience.detail!=null"
                                                            [innerHTML]="experience.detail.trim().split('\n').join('<br>')">
                                                        </div>

                                                    </div>
                                                    <div class="sub" [ngClass]="{'bg-success':experience.conf_score>range_conf.success,'bg-warning':
                                                    experience.conf_score>range_conf.warning && experience.conf_score < range_conf.success, 'bg-danger':
                                                    experience.conf_score<range_conf.warning}">
                                                        <div class="">{{experience.conf_score*100|number:'1.2-2'}}%
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="" *ngFor="let education of resume_parser.education">
                                                <div class="main" [ngClass]="{'border border-success':education.conf_score>range_conf.success,'border border-warning':
                                                    education.conf_score>range_conf.warning && education.conf_score < range_conf.success, 'border border-danger':
                                                    education.conf_score<range_conf.warning}"
                                                    style="border-style: dashed !important;">
                                                    <div class=""></div>
                                                    <div class="ms-3">
                                                        <div><span class="text-dark"
                                                                data-feather="calendar"></span><span
                                                                style="width:fit-content">
                                                                {{education.start_time|date:'d/M/yyyy'}} <span
                                                                    *ngIf="education.start_time && education.end_time">
                                                                    - </span>
                                                                {{education.end_time|date:'d/M/yyyy'}}</span></div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span>:
                                                            {{education.major}}</div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span>:
                                                            {{education.school}}</div>
                                                        <div *ngIf="education.diploma!=null && education.diploma!=''">
                                                            <span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DIPLOMA'"></span>:
                                                            {{education.diploma}}
                                                        </div>

                                                        <div *ngIf="education.gpa!=null && education.gpa!=''"><span
                                                                class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span>:
                                                            {{education.gpa}}</div>
                                                        <div
                                                            *ngIf="education.picklist_major!=null && education.picklist_major!=''">
                                                            <span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span>:
                                                            {{education.picklist_major}}
                                                        </div>


                                                    </div>
                                                    <div class="sub" [ngClass]="{'bg-success':education.conf_score>range_conf.success,'bg-warning':
                                                        education.conf_score>range_conf.warning && education.conf_score < range_conf.success, 'bg-danger':
                                                        education.conf_score<range_conf.warning}">
                                                        <div class="">{{education.conf_score*100|number:'1.2-2'}}%</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card" *ngIf="tag_resultConnectSf4c_exit && resume_parser.status!='Failure'&&resume_parser.status!='Duplicated'">

                                    <div class="" id="headingFour">
                                        <h5 class="mb-0">

                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.JOB_REQ_MATCHING'">

                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseFour" class="collapse show" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div>
                                                <form #MatchingForm="ngForm">

                                                    <div class="d-flex justify-content-between align-items-center">


                                                        <div class="col-md-2"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.SELECT_JR'">:
                                                        </div>

                                                        <div class="col-md-7">

                                                            <ng-select id="JR" [items]="items_jr" bindLabel="jobTitle"
                                                                bindValue="jobReqId"
                                                                [placeholder]="'RESUME_PARSER.DETAIL_RESUME.PLACEHOLDER.PLACEHOLDER_SELECT_MATCHING'|translate"
                                                                name="selectedJr" [(ngModel)]="selectedJr" required>

                                                            </ng-select>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <button
                                                                style="margin-left: 10px;font-size:12px;padding: 0.5rem 0.5rem;"
                                                                *ngIf="loading_match;else noLoadingMatch"
                                                                class="btn btn-primary rounded-pill btn-sm"
                                                                type="button" disabled>
                                                                <span class="spinner-border spinner-border-sm mr-1"
                                                                    role="status" aria-hidden="true"></span>
                                                                <span
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.ANALYSIS'"></span>
                                                            </button>
                                                            <ng-template #noLoadingMatch>
                                                                <button [disabled]="!MatchingForm.form.valid"
                                                                    class="btn btn-primary rounded-pill btn-sm"
                                                                    style="margin-left: 10px;font-size:12px;padding: 0.5rem 0.5rem;"
                                                                    rippleEffect (click)="Post_matching_jr()">
                                                                    <span
                                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.ANALYSIS'">
                                                                    </span>
                                                                </button>
                                                            </ng-template>

                                                        </div>

                                                    </div>

                                                </form>
                                                <hr>
                                                <p *ngIf="errorMessagesMatchingJr; else NoError"
                                                    class="badge badge-warning">{{errorMessagesMatchingJr}}</p>
                                                <ng-template #NoError>
                                                    <p *ngIf="loading_match;else Result" style="margin-top: 30px;"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.SEARCHING'"></p>

                                                    <ng-template #Result>
                                                        <div *ngIf="matching_jr.id!=null && showJrMatching">
                                                            <div class="d-flex align-items-center">
                                                                <div class="col-md-6">
                                                                    <div class="d-flex justify-content-start align-items-center"
                                                                        *ngIf="matching_jr.overall_score">
                                                                        <div class="col-md-6"><span
                                                                                class="font-weight-bolder"
                                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.MATCHING_POINT'"></span><span>:
                                                                            </span> </div>
                                                                        <div class="col-md-6">
                                                                            <app-circle-chart
                                                                                [overallScore]="matching_jr.overall_score*100"></app-circle-chart>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="d-flex justify-content-start align-items-center"
                                                                        *ngIf="matching_jr.experience_score">
                                                                        <div class="col-md-6"><span
                                                                                class="font-weight-bolder"
                                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EXPERIENCE'"></span><span>:
                                                                            </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar showValue="true" [type]="{
                                                                                    'success':matching_jr.experience_score>0.8,
                                                                                    'warning':matching_jr.experience_score<0.8 && matching_jr.experience_score>0.5,
                                                                                    'danger':matching_jr.experience_score<0.5
                                                                                }" [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.experience_score>0.8,
                                                                                    'progress-bar-warning':matching_jr.experience_score<0.8 && matching_jr.experience_score>0.5,
                                                                                    'progress-bar-danger':matching_jr.experience_score<0.5
                                                                                }" [height]="progressbarHeight"
                                                                                [value]="matching_jr.experience_score*100"></ngb-progressbar>

                                                                        </div>

                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center"
                                                                        *ngIf="matching_jr.education_score">
                                                                        <div class="col-md-6"><span
                                                                                class="font-weight-bolder"
                                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION_'"></span><span>:
                                                                            </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar showValue="true" [type]="{
                                                                                    'success':matching_jr.education_score>0.8,
                                                                                    'warning':matching_jr.education_score<0.8 && matching_jr.education_score>0.5,
                                                                                    'danger':matching_jr.education_score<0.5
                                                                                }" [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.education_score>0.8,
                                                                                    'progress-bar-warning':matching_jr.education_score<0.8 && matching_jr.education_score>0.5,
                                                                                    'progress-bar-danger':matching_jr.education_score<0.5
                                                                                }" [height]="progressbarHeight"
                                                                                [value]="matching_jr.education_score*100"></ngb-progressbar>

                                                                        </div>

                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center"
                                                                        *ngIf="matching_jr.language_score">
                                                                        <div class="col-md-6"><span
                                                                                class="font-weight-bolder"
                                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.LANGUAGE'"></span><span>:
                                                                            </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar showValue="true" [type]="{
                                                                                    'success':matching_jr.language_score>0.8,
                                                                                    'warning':matching_jr.language_score<0.8 && matching_jr.language_score>0.5,
                                                                                    'danger':matching_jr.language_score<0.5
                                                                                }" [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.language_score>0.8,
                                                                                    'progress-bar-warning':matching_jr.language_score<0.8 && matching_jr.language_score>0.5,
                                                                                    'progress-bar-danger':matching_jr.language_score<0.5
                                                                                }" [height]="progressbarHeight"
                                                                                [value]="matching_jr.language_score*100"></ngb-progressbar>

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            
                                                            <div *ngIf="matching_jr?.detail?.skill?.length>0">
                                                                <p><span class="font-weight-bolder"
                                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE_SKILL'"></span>
                                                                </p>
                                                                <div *ngFor="let skill of matching_jr.detail.skill"
                                                                    class="list-inline" style="display: inline-block">
                                                                    <span class="badge bg-primary"
                                                                        style="margin-left: 2px;">{{skill.value}}</span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </ng-template>
                                                </ng-template>
                                            </div>



                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
                <ng-template #modalBasicShareUser let-modal>
                    <form [formGroup]="shareResumeForm">
                        <div class="modal-header">
                            <h4 class="modal-title" [translate]="'RESUME_PARSER.DETAIL_RESUME.TITLE_SHARE'">
                                Share user
                            </h4>
                        </div>
                        <div class="modal-body" ngbAutofocus>

                            <div class="form-group" style="height: 30vh;">
                                <label for="selectedUser"><span
                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.USER'">User</span><b
                                        style="color:red">*</b></label>
                                <ng-select [multiple]="true" id="user" formControlName="share_to"
                                    [placeholder]="'RESUME_PARSER.DETAIL_RESUME.PLACEHOLDER_SHARE'|translate">
                                    <ng-option *ngFor="let user of listUser" [value]="user.id">
                                        {{user.name}}
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="shareResumeForm?.controls.share_to.touched && shareResumeForm?.controls.share_to.errors?.required"
                                    class="text-danger mt-1">
                                    <small class="form-text text-danger" [translate]="REQUIRED"></small>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <button [translate]="'RESUME_PARSER.DETAIL_RESUME.CONFIRM'"
                                [disabled]="!shareResumeForm.valid" type="button" (click)="shareResume()"
                                (click)="modal.close('Accept click')" class="btn btn-primary mr-1">Share</button>
                            <button (click)="modal.close('Accept click')" type="reset" rippleEffect
                                class="btn btn-secondary"
                                [translate]="'RESUME_PARSER.DETAIL_RESUME.CANCEL'">Cancel</button>
                        </div>
                    </form>
                </ng-template>

                <div class="col-md-6" *ngIf="tab_sf4c" style="position: relative;overflow-y: auto;">
                    <div>

                        <div class="" style="padding-top: 10px;">
                            <div class="d-flex justify-content-start align-items-center">
                                <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE_SF4C'"></h1>

                            </div>
                            <div id="accordionExample">
                                <div class="card">
                                    <div class="" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">

                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span>:
                                                    {{sf4cCandidate.firstName}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span>:
                                                    {{sf4cCandidate.lastName}}</p>
                                            </div>

                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.DOB'"></span>:
                                                    {{sf4cCandidate.dateOfBirth}}</p>
                                            </div>

                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span>:
                                                    {{sf4cCandidate.gender}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span>:
                                                    {{sf4cCandidate.address}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span>:
                                                    {{sf4cCandidate.primaryEmail}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span>:
                                                    {{sf4cCandidate.cellPhone}}</p>
                                            </div>
                                            <!-- <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'"></span>:
                                                    {{sf4cCandidate.currentTitle}}</p>
                                            </div>
                                            <div>
                                                <p><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'"></span>:
                                                    {{sf4cCandidate.currentCompany}}</p>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class=""
                                                *ngFor="let experience of sf4cCandidate.outsideWorkExperience">
                                                <div class="main"
                                                    style="border: 1px solid #198754;border-style: dashed;">
                                                    <div class=""></div>
                                                    <div class="ms-3">

                                                        <div><span class="text-dark"
                                                                data-feather="calendar"></span><span
                                                                style="width:fit-content">
                                                                {{experience.startDate}}
                                                                <span
                                                                    *ngIf="experience.startDate && experience.endDate">
                                                                    - </span>
                                                                {{experience.endDate}}</span>
                                                        </div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span>:
                                                                {{experience.startTitle}}</span></div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span>:
                                                            {{experience.employer}}</div>
                                                        <!-- <div class="font-weight-bolder">{{experience.startTitle}}</div>
                                                        <div class="font-weight-bolder">{{experience.employer}}</div> -->
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DESCRIPTION_JOB'"></span>:
                                                        </div>
                                                        <div class="ml-1" *ngIf="experience.status!=null"
                                                            [innerHTML]="experience.status.trim().split('\n').join('<br>')">
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="" *ngFor="let education of sf4cCandidate.education">
                                                <div class="main"
                                                    style="border: 1px solid #198754;border-style: dashed;">
                                                    <div class=""></div>
                                                    <div class="ms-3">
                                                        <div><span class="text-dark"
                                                                data-feather="calendar"></span><span
                                                                style="width:fit-content">
                                                                {{education.startDate}}
                                                                <span *ngIf="education.startDate && education.endDate">
                                                                    - </span>
                                                                {{education.endDate}}</span>
                                                        </div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span>:
                                                            {{education.major1}}</div>
                                                        <div><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span>:
                                                            {{education.school}}</div>
                                                        <div *ngIf="education.diploma!=null && education.diploma!=''">
                                                            <span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DEGREE'"></span>:
                                                            {{education.degree}}
                                                        </div>
                                                        <div *ngIf="education.gpa!=null && education.gpa!=''"><span
                                                                class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span>:
                                                            {{education.gpa}}</div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="shared-user"
                overlayClass="modal-backdrop">
                <app-shared-user (detailListShared)="shareUser($event)" [listShared]="listShared"></app-shared-user>
            </core-sidebar>
        </div>
    </div>
</ng-template>