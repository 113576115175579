import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { FlatpickrOptions } from 'ng2-flatpickr';

import { AccountSettingsService } from 'app/main/pages/account-settings/account-settings.service';
import { Role, User } from 'app/auth/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { MappingPicklist, ResultConnectSf4c, ResultSourceMapping } from 'app/main/resume-parser/models/resume.model';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import Swal from 'sweetalert2';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { CoreConfigService } from '@core/services/config.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  // public
  public contentHeader: object;
  public data: any;
  public birthDateOptions: FlatpickrOptions = {
    altInput: true
  };
  //password
  public passwordTextTypeOld = false;
  public passwordTextTypeNew = false;
  public passwordTextTypeRetype = false;
  public avatarImage: string;
  public ChangeProfileForm: FormGroup
  public submitted = false;
  // private
  private _unsubscribeAll: Subject<any>;
  public errorMessagesGetDetailUser!: any
  public errorMessagesResultResumeSettings!: any
  public errorMessagesPutResultResumeSettings!: any
  public errorMessagesSyncEmail!: any
  public errorMessagesUpdateUser!: any
  public user: User = new User()
  public first_name!: string | undefined
  public last_name!: string | undefined
  public email!: string | undefined
  public password!: string
  public company_taxcode!: string
  public auto_approve_done_resume: boolean = false
  public auto_sync_email: boolean = false
  public id!: number
  public approve_score: number
  public censor_contact: boolean
  public webhook_parsing_api: string
  public webhook_dev_parsing_api: string
  public webhook_matching_api: string
  public webhook_dev_matching_api: string
  public webhook_recommend_api: string
  public webhook_recommend_dev_api: string

  public tag_sync_email = false
  public status!: string
  public edit_user = false
  public password_arr: string[] = []
  public EditProfileForm: FormGroup
  //Connect Sf4c
  ConnectSf4cForm!: FormGroup;
  @ViewChild('InputVar')
  myInputVariable!: ElementRef;
  public loading = false
  public errorMessagesResultConnectSf4c!: any
  public errorMessagesResultConnectSf4cs!: any
  public listCredential: ResultConnectSf4c[]
  public resultConnectSf4c!: ResultConnectSf4c
  public detailCredential: ResultConnectSf4c
  public tag_resultConnectSf4c = false
  public tag_resultConnectSf4c_exit = false
  public editCred = false
  //Source mapping
  public resultSourceMapping: ResultSourceMapping = new ResultSourceMapping()
  public idMapping!: number
  public id_company!: number
  public emailMapping!: string
  public gender!: string
  public address!: string
  public date_of_birth!: string
  public firstNameMapping!: string
  public lastNameMapping!: string
  public phone_number!: string
  public current_title!: string
  public current_company!: string
  public id_education!: string
  public start_time_education!: string
  public end_time_education!: string
  public major!: string
  public school!: string
  public diploma!: string
  public gpa!: string
  public id_experience!: string
  public start_time_experience!: string
  public end_time_experience!: string
  public position!: string
  public company!: string
  public detail!: string
  public matching_score!: string
  public autoupload_score!: number
  public errorMessagesGetSourceMapping!: any
  public edit_source_mapping = false
  public picklistDiploma: string;
  public picklistIndustry: string
  public picklistMajor: string
  //manage user
  public errorMessagesListUser!: any
  public users: User[] = []
  public usersCredential: User[] = []
  public usersAssign: User[] = []
  public currentUser: User;
  public searchText: string = '';
  public searchAssignEmail: string = ''
  page = 1;
  pageSize = 5;
  basicSelectedOption = 5
  pageAssignUser = 1;
  pageSizeAssignUser = 5;
  basicSelectedOptionAssignUser = 5
  createUserForm: FormGroup
  public _checkbox: number
  companys: any
  subCompanys: any
  deleteUserForm = this.fb.group({
    checkUser: ["", Validators.required]
  })

  UpdateUserTalentPoolForm = this.fb.group({
    is_accessible: false
  })
  public editBoolean = false

  passwordTextType: boolean = false;
  private horizontalWizardStepper: Stepper;
  public listPicklist: MappingPicklist[] = []
  public createMappingPicklistForm: FormGroup
  widthColumn: number = 0;
  public ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent) tableMappingPicklist: DatatableComponent;
  isLoading = false
  language: any;
  chr_label_items:any[] = []
  selectedType = ''
  typeItems = [
    // {name: "category", vn_name: "ngành nghề"},
    {name: "diploma", vn_name: "bằng cấp"},
    {name: "industry", vn_name: "lĩnh vực"},
    {name: "major", vn_name: "chuyên ngành"},

  ]
  public role = Role
  selectedTab = 'tab-overview'

  /**
   * Constructor
   *
   * @param {AccountSettingsService} _accountSettingsService
   */
  constructor(
    private _accountSettingsService: AccountSettingsService,
    private _router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    private resumesService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    private _coreConfigService: CoreConfigService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._unsubscribeAll = new Subject();
    this.EditProfileForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      email: ['']
    })
    this.ChangeProfileForm = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validator: [this.checkPasswords, this.checkPassword] });
    this.ConnectSf4cForm = new FormGroup({
      username: new FormControl('', Validators.required),
      user: new FormControl('',Validators.required)

    });
    this.createMappingPicklistForm = new FormGroup({
      type: new FormControl('', Validators.required),
      chr_label: new FormControl(null, Validators.required),
      hcm_system_label_id: new FormControl('', Validators.required),
      hcm_system_eng_label: new FormControl('', Validators.required),
      hcm_system_vn_label: new FormControl('', Validators.required),

    })
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.searchText = ''
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Password Text Type Old
   */
  togglePasswordTextTypeOld() {
    this.passwordTextTypeOld = !this.passwordTextTypeOld;
  }

  /**
   * Toggle Password Text Type New
   */
  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  /**
   * Toggle Password Text Type Retype
   */
  togglePasswordTextTypeRetype() {
    this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
  }
  showUsers() {
    this.listUser('', null)
  }

  /**
   * Upload Image
   *
   * @param event
   */
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['new_password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    let old_password = group.controls['old_password'].value;

    return pass !== old_password ? true : { notMatch: true }

  }
  checkPassword(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['new_password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    let old_password = group.controls['old_password'].value;

    return pass == confirmPass ? true : { notSame: true }

  }
  changePassword() {
    this.submitted = true;
    if (this.ChangeProfileForm.invalid) {
      return;
    }
    let formData: any = {}
    formData["current_password"] = this.ChangeProfileForm.value.old_password
    formData["new_password"] = this.ChangeProfileForm.value.new_password
    this._authenticationService.changePassword(formData).subscribe(
      (res) => {
        this.status = res;
        // this.toastr.success('Change Password Success',);
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    )
  }
  get f() {
    return this.ChangeProfileForm.controls;
  }



  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  resumeSettings() {
    this.resumesService.resumeSettings().subscribe(
      (res) => {
        this.auto_approve_done_resume = res.auto_approve_done_resume;
        this.auto_sync_email = res.auto_sync_email;
        this.id = res.id
        this.censor_contact = res.censor_contact;
        this.approve_score = res.approve_score;
        this.webhook_parsing_api = res.webhook_parsing_api;
        this.webhook_dev_parsing_api = res.webhook_dev_parsing_api;
        this.webhook_matching_api = res.webhook_matching_api;
        this.webhook_dev_matching_api = res.webhook_dev_matching_api;
        this.webhook_recommend_api = res.webhook_recommend_api;
        this.webhook_recommend_dev_api = res.webhook_recommend_dev_api;
      }
    )
  }
  editUser() {
    this.currentUser = this._authenticationService.currentUserValue
    this.EditProfileForm.patchValue({
      first_name: this.currentUser.first_name,
      last_name: this.currentUser.last_name,
      email: this.currentUser.email
    })
  }
  saveProfile() {
    const id = Number(this.currentUser.id);
    let formData: any = {}
    formData["first_name"] = this.EditProfileForm.value.first_name
    formData["last_name"] = this.EditProfileForm.value.last_name
    formData["email"] = this.EditProfileForm.value.email

    this._authenticationService.updateUser(id, formData).subscribe(
      (res) => {
        this._authenticationService.setCurrentUserValue(res)
        this.first_name = res.first_name
        this.last_name = res.last_name
        this.email = res.email
        // this.toastr.success("Edit user success")
        this.editUser()
        this.listUser('', null)

      },
      (err) => {
        this.errorMessagesUpdateUser = err
        this.toastr.error(err)
      }
    )
  }
  putResumeSettings() {
    let formData: any = {}
    formData["id"] = this.id;
    formData["auto_approve_done_resume"] = this.auto_approve_done_resume;
    formData["auto_sync_email"] = this.auto_sync_email;
    formData["censor_contact"] = this.censor_contact;
    formData["approve_score"] = this.approve_score;
    formData["webhook_parsing_api"] = this.webhook_parsing_api;
    formData["webhook_dev_parsing_api"] = this.webhook_dev_parsing_api;
    formData["webhook_matching_api"] = this.webhook_matching_api;
    formData["webhook_dev_matching_api"] = this.webhook_dev_matching_api;
    formData["webhook_recommend_api"] = this.webhook_recommend_api;
    formData["webhook_recommend_dev_api"] = this.webhook_recommend_dev_api;
    this.resumesService.putResumeSettings(formData).subscribe(
      (res) => {
        // this.toastr.success('Auto Setting Config Success',);

      },
      (err) => {
        this.errorMessagesPutResultResumeSettings = err
      }
    )
  }
  syncCv() {
    this.resumesService.syncEmail().subscribe(
      (res) => {
        this.status = res.status;
        // this.toastr.success('Sync Email Success',);
      },
      (err) => {
        this.errorMessagesSyncEmail = err
      }
    )
  }
  //connect sf4c
  selectedFiles?: FileList;

  onFileSelect(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        // const file = event.target.files.item(0);
        this.ConnectSf4cForm.patchValue({ file: file });
      }
    }
  }
  GetCredential() {
    this.tag_resultConnectSf4c_exit = false

    this.loading = true
    let id = this.resultConnectSf4c?.id
    let username = this.ConnectSf4cForm.value.username
    let user = this.ConnectSf4cForm.value.user

    if (this.editCred) {
      this.resumesService.updateCredential(id, username, user).subscribe(
        (res) => {
          this.resultConnectSf4c = res;
          this.tag_resultConnectSf4c = true;
          this.tag_resultConnectSf4c_exit = true
          this.editCred = false
          this.modalService.dismissAll()
          this.getListCredential()
          // this.toastr.success("Edit credential success")
          this.loading = false
        },
        (err) => {
          console.log("err", err.Token_err[0])
          this.toastr.error(err)
          this.errorMessagesResultConnectSf4c = err
          this.loading = false

        }
      )
    } else {
      this.resumesService.getCredential(username, user).subscribe(
        (res) => {
          this.resultConnectSf4c = res;
          this.tag_resultConnectSf4c = true;
          this.tag_resultConnectSf4c_exit = true
          this.modalService.dismissAll()
          this.getListCredential()
          if(this.currentUser.email==user){
            window.location.reload()
          }
          // this.toastr.success("Create credential success")
          this.loading = false
        },
        (err) => {
          this.toastr.error(err)
          this.errorMessagesResultConnectSf4c = err
          this.loading = false

        }
      )
    }

  }
  getListCredential() {
    this.resumesService.getListCredential().subscribe(
      (res) => {
        this.listCredential = res;
      },
      (err) => {
        this.errorMessagesResultConnectSf4cs = err
      }
    )
  }
  getDetailCredential() {
    let id = this.currentUser.id
    this.resumesService.getDetailCredential(id).subscribe(
      (res) => {
        this.tag_resultConnectSf4c_exit = true
        this.resultConnectSf4c = res
      },
      (err) => {
        console.log("err", err)
      }
    )
  }
  editConnectSf4c() {

    this.tag_resultConnectSf4c_exit = false
    this.editCred = true
    this.ConnectSf4cForm.patchValue({
      username: this.resultConnectSf4c?.username,
      user: this.resultConnectSf4c?.user
    })

  }
  deleteConnectSf4c(id: any) {
    Swal.fire({
      title: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.DELETE_C"),
      text: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.resumesService.deleteCredential(id).subscribe(
          (res) => {
            // this.toastr.success(
            //   this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.DELETE_C_SUC"),
            //   this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
            //   {
            //     toastClass: "toast ngx-toastr",
            //     closeButton: true,
            //   }
            // );
            this.tag_resultConnectSf4c_exit = false
            this.getListCredential()
          },
          (err) => {
            this.toastr.error(
              this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.ERROR"),
              this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  //Source mapping
  SourceMapping() {
    let education = {
      id: this.id_education,
      start_time: this.start_time_education,
      end_time: this.end_time_education,
      picklist_major: this.major,
      school: this.school,
      diploma: this.diploma,
      gpa: this.gpa
    }
    let experience = {
      id: this.id_experience,
      start_time: this.start_time_experience,
      end_time: this.end_time_experience,
      position: this.position,
      company: this.company,
      detail: this.detail
    }
    let config = {
      email: this.emailMapping,
      gender: this.gender,
      address: this.address,
      date_of_birth: this.date_of_birth,
      first_name: this.firstNameMapping,
      last_name: this.lastNameMapping,
      phone_number: this.phone_number,
      current_title: this.current_title,
      current_company: this.current_company,
      education: { "education": education },
      experience: { "outsideWorkExperience": experience }
    }
    let picklist_fields = {
      diploma: this.picklistDiploma,
      industry: this.picklistIndustry,
      picklist_major: this.picklistMajor,
    }
    let jobapplication_config = {
      matching_score: this.matching_score
    }
    let autoupload_score = {
      autoupload_score: Number(this.autoupload_score)
    }
    this.resumesService.edit_source_mapping(this.idMapping, config, jobapplication_config, this.id_company, this.autoupload_score, picklist_fields).subscribe(
      (res) => {
        // this.toastr.success("Mapping success");
        this.GetSourceMapping()
      },
      (err) => {
        this.toastr.error(err)
        this.errorMessagesGetSourceMapping = err
      }
    )
  }
  GetSourceMapping() {
    this.resumesService.get_source_mapping().subscribe(
      (res) => {
        this.resultSourceMapping = res
        this.idMapping = this.resultSourceMapping.id
        this.id_company = this.resultSourceMapping.company
        this.emailMapping = this.resultSourceMapping.config.email
        this.gender = this.resultSourceMapping.config.gender
        this.address = this.resultSourceMapping.config.address
        this.date_of_birth = this.resultSourceMapping.config.date_of_birth
        this.firstNameMapping = this.resultSourceMapping.config.first_name
        this.lastNameMapping = this.resultSourceMapping.config.last_name
        this.phone_number = this.resultSourceMapping.config.phone_number
        this.current_title = this.resultSourceMapping.config.current_title
        this.current_company = this.resultSourceMapping.config.current_company
        this.id_education = this.resultSourceMapping.config.education.education.id
        this.start_time_education = this.resultSourceMapping.config.education.education.start_time
        this.end_time_education = this.resultSourceMapping.config.education.education.end_time
        this.major = this.resultSourceMapping.config.education.education.picklist_major
        this.school = this.resultSourceMapping.config.education.education.school
        this.diploma = this.resultSourceMapping.config.education.education.diploma
        this.gpa = this.resultSourceMapping.config.education.education.gpa
        this.id_experience = this.resultSourceMapping.config.experience.outsideWorkExperience.id
        this.start_time_experience = this.resultSourceMapping.config.experience.outsideWorkExperience.start_time
        this.end_time_experience = this.resultSourceMapping.config.experience.outsideWorkExperience.end_time
        this.position = this.resultSourceMapping.config.experience.outsideWorkExperience.position
        this.company = this.resultSourceMapping.config.experience.outsideWorkExperience.company
        this.detail = this.resultSourceMapping.config.experience.outsideWorkExperience.detail
        this.matching_score = this.resultSourceMapping.jobapplication_config.matching_score
        this.autoupload_score = this.resultSourceMapping.autoupload_score
        this.picklistDiploma = this.resultSourceMapping.picklist_fields?.diploma
        this.picklistIndustry = this.resultSourceMapping.picklist_fields?.industry
        this.picklistMajor = this.resultSourceMapping.picklist_fields?.picklist_major

      },
      (err) => {
        this.errorMessagesGetSourceMapping = err
      }
    )
  }
  //manage user
  Search() {
    // alert(this.searchText)
    let users_cp: any;
    this._accountSettingsService.listUser.subscribe(
      (res) => {
        users_cp = res;
      }
    )
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();

      this.users = users_cp.filter((user: any) => {
        return user.email.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.users = users_cp
    }
  }
  SearchAssignEmail() {
    // alert(this.searchText)
    let users_cp: any;
    this._accountSettingsService.listUser.subscribe(
      (res) => {
        users_cp = res;
      }
    )
    if (this.searchAssignEmail !== "") {
      let searchValue = this.searchAssignEmail.toLocaleLowerCase();

      this.usersAssign = users_cp.filter((user: any) => {
        return user.email.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.usersAssign = users_cp
    }
  }
  listUser(dynamic_string: any, id: any) {
    this._authenticationService.listUsers(dynamic_string).subscribe(
      (res) => {
        if (dynamic_string == '') {
          // this.usersCredential = res.results
          // let emailUser = this.listCredential.map(item=>item.user)
          // let users = this.usersCredential.filter((e)=>
          //   !emailUser.includes(e.email)
          // )
          this.usersCredential = res.results
          this.users = res.results
          // this.users = Object.assign([], this.users);
          this._accountSettingsService.setNewListUser(this.users);
        } else {
          this.usersAssign = res.results;
          this.usersAssign = Object.assign([], this.usersAssign);
          this.usersAssign = this.usersAssign.filter((user) => user.id !== id);
          if (this.usersAssign.length == 0) {
            this.DeleteUser(id)
          }
        }
      },
      (err) => {
        this.errorMessagesListUser = err
      }
    )
  }
  get f_user() {
    return this.createUserForm.controls;
  }
  activeUser(info: any) {
    this._authenticationService.adminUpdateUser(info.id, info.first_name, info.last_name, info.email, !info.is_active, info.is_site_admin, info.sub_company).subscribe(
      (res) => {
        if (this.currentUser.id == info.id) {
          this._authenticationService.setCurrentUserValue(res)
          this.editUser()
          this.searchText = ''
        }
        this.createUserForm.reset();
        this.modalService.dismissAll();
        this.listUser('', null);

      },
      (err) => {
        if(this.language=='en'){
          this.toastr.error(err.error.message.en)
        }else{
          this.toastr.error(err.error.message.vn)
        }   
        this.createUserForm.reset();
        this.modalService.dismissAll()
      }
    )
  }
  activeAdminUser(info: any) {
    this._authenticationService.adminUpdateUser(info.id, info.first_name, info.last_name, info.email, info.is_active, !info.is_site_admin, info.sub_company).subscribe(
      (res) => {
        if (this.currentUser.id == info.id) {
          this._authenticationService.setCurrentUserValue(res)
          this.editUser()
          this.searchText = ''
        }
        this.createUserForm.reset();
        this.modalService.dismissAll();
        this.listUser('', null);

      },
      (err) => {
        if(this.language=='en'){
          this.toastr.error(err.error.message.en)
        }else{
          this.toastr.error(err.error.message.vn)
        }  
        this.createUserForm.reset();
        this.modalService.dismissAll()
      }
    )
  }
  modalOpenDetailCredential(modalDetailCredential: any){
    this.modalService.open(modalDetailCredential, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  modalOpenCreateCredential(modalBasic: any, info: any=null){
    if(info){
      this.editCred = true
      this.ConnectSf4cForm.patchValue({
        username: info?.username,
        user: info?.user
      })
    }else{
      this.editCred = false
      this.ConnectSf4cForm.patchValue({
        username: null,
        user: null
      })
    }
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
      scrollable: true
    });
  }
  modalOpenCreateUser(modalBasic: any, info: any = null) {
    if (info) {
      this.editBoolean = true
      if (this.currentUser.role == 'Admin' || this.currentUser.role == 'Super admin'){
        this.createUserForm = this.fb.group({
          first_name: ["", Validators.required],
          last_name: ["", Validators.required],
          sub_company: [null, Validators.required],
          email: ["", Validators.email],
          is_active: false,
          is_site_admin: false,
          id: [""]
        })
      }else{
        this.createUserForm = this.fb.group({
          first_name: ["", Validators.required],
          last_name: ["", Validators.required],
          company: [null, Validators.required],
          sub_company: [null, Validators.required],
          email: ["", Validators.email],
          is_active: false,
          is_site_admin: false,
          id: [""]
        })
      }
      this.createUserForm.get("first_name").setValue(info.first_name);
      this.createUserForm.get("last_name").setValue(info.last_name);
      this.createUserForm.get("sub_company").setValue(info.sub_company);
      // this.createUserForm.get("company").setValue(info.company);
      this.createUserForm.get("email").setValue(info.email);
      // this.createUserForm.get("password").setValue(info.password);
      this.createUserForm.get("is_active").setValue(info.is_active);
      this.createUserForm.get("is_site_admin").setValue(info.is_site_admin);
      // this.createUserForm.get("checkSearchEngine").setValue(info.checkSearchEngine)
      // this.createUserForm.get("checkParseResume").setValue(info.checkParseResume)
      this.createUserForm.get("id").setValue(info.id)

    } else {
      this.editBoolean = false
      if (this.currentUser.role == 'Admin' || this.currentUser.role == 'Super admin') {
        this.createUserForm = this.fb.group({
          first_name: ["", Validators.required],
          last_name: ["", Validators.required],
          sub_company: [null, Validators.required],
          email: ["", [Validators.required, Validators.email]],
          password: ["", Validators.required],
          is_site_admin: false,
          id: [""],
          is_active: false
        })
      }else{
        this.createUserForm = this.fb.group({
          first_name: ["", Validators.required],
          last_name: ["", Validators.required],
          company: [null, Validators.required],
          sub_company: [null, Validators.required],
          email: ["", [Validators.required, Validators.email]],
          password: ["", Validators.required],
          is_site_admin: false,
          id: [""],
          is_active: false
        })
      }

    }
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
      scrollable: true
    });
  }
  getListCompany() {
    this._authenticationService.list_company().subscribe(
      (res) => {
        this.companys = res.results
      }
    )
  }
  getListSubCompany(){
    this._authenticationService.listSubCompany().subscribe(
      (res)=>{
        this.subCompanys = res
      }
    )
  }
  changeCompany(e: any) {
    this.createUserForm.get('company')?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  changeSubCompany(e: any) {
    this.createUserForm.get('sub_company')?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  changeUser(e: any) {
    this.ConnectSf4cForm.get('user')?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  CreateUser() {
    let formData: any = {}
    // let permissions: string[]=[]
    // if(this.createUserForm.value.checkSearchEngine){
    //   permissions.push("search_engine")
    // }
    // if(this.createUserForm.value.checkParseResume){
    //   permissions.push("parse_cv")
    // }
    let first_name = this.createUserForm.value.first_name
    let last_name = this.createUserForm.value.last_name
    let email = this.createUserForm.value.email
    let password = this.createUserForm.value.password
    let is_active = this.createUserForm.value.is_active
    let is_site_admin = this.createUserForm.value.is_site_admin
    let id = this.createUserForm.get("id").value
    let sub_company = this.createUserForm.value.sub_company
    formData["first_name"] = this.createUserForm.value.first_name
    formData["last_name"] = this.createUserForm.value.last_name
    formData["sub_company"] = this.createUserForm.value.sub_company
    if (this.currentUser.is_superuser) {
      formData["company"] = this.createUserForm.value.company ? Number(this.createUserForm.value.company) : null
    } else {
      formData["company"] = this.currentUser.company
    }
    formData["email"] = this.createUserForm.value.email
    formData["password"] = this.createUserForm.value.password
    // formData["permissions"] = permissions
    // formData["is_active"] = this.createUserForm.value.is_active!=null?true:false
    formData["is_site_admin"] = this.createUserForm.value.is_site_admin != null ? true : false

    if (id != null && id != undefined) {
      this._authenticationService.adminUpdateUser(id, first_name, last_name, email, is_active, is_site_admin, sub_company).subscribe(
        (res) => {
          if (this.currentUser.id == id) {
            this._authenticationService.setCurrentUserValue(res)
            this.editUser()
            this.searchText = ''
          }
          // this.toastr.success('Edit user success');
          this.createUserForm.reset();
          this.modalService.dismissAll();
          this.listUser('', null);

        },
        (err) => {
          // if(err.error.email[0]){
          //   this.toastr.error(err.error.email[0])
          // }else{
          //   this.toastr.error(err.error.error)
          // }
          if(this.language=='en'){
            this.toastr.error(err.error.message.en)
          }else{
            this.toastr.error(err.error.message.vn)
          }
          this.createUserForm.reset();
          this.modalService.dismissAll()
        }
      )
    } else {
      this._authenticationService.createUser(formData).subscribe(
        (res) => {
          // this.toastr.success('Create user success');
          this.createUserForm.reset();
          this.modalService.dismissAll();
          this.listUser('', null);
          this.searchText = ''
        },
        (err) => {
          if(this.language=='en'){
            this.toastr.error(err.error.message.en)
          }else{
            this.toastr.error(err.error.message.vn)
          }
          this.createUserForm.reset();
          this.modalService.dismissAll()
        }
      )
    }
  }

  modalOpenDeleteUser(modalBasic: any, id: any, company: number) {
    this.page = 1
    if (company) {
      let dynamic_string = `?company=${company}`
      this.listUser(dynamic_string, id)

    } else {
      this.listUser('', null)
      this.usersAssign = this.users.filter((user) => user.id !== id);

    }

    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true,
      backdrop: "static",
    });

  }
  // onCheckboxChange(id: string, isChecked: boolean) {

  //   if (isChecked) {
  //     this._checkbox = id
  //   } else {
  //     this._checkbox = ""
  //   }
  // }
  onCheckboxChange(e: any) {
    let checkUser: any = this.deleteUserForm.get('checkUser');
    if (e.target.checked) {
      checkUser = new FormControl(e.target.value);
      this._checkbox = e.target.value

    } else {
      checkUser = new FormControl()
      this._checkbox = null
    }
  }
  cancel(){
    this._checkbox = null
  }
  DeleteUser(id: any) {
    if (this._checkbox == undefined) {
      this._checkbox = this.currentUser.id
    }
    this._authenticationService.deleteUser(id, this._checkbox).subscribe(
      (res) => {
        // this.toastr.success('Delete user success');
        this.modalService.dismissAll();
        this.listUser('', null);
        this._checkbox = null
        this.searchText = ''
      },
      (err) => {
        this.toastr.error(err.error.messsage);
        this.listUser('', null);
        this._checkbox = null
        this.modalService.dismissAll();
      }
    )
  }
  //Talent pool
  modalOpenUpdatePermissionTalentPool(modalBasic: any) {
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  UpdateUserPermissionTalentPool(id: any) {
    let is_accessible = this.UpdateUserTalentPoolForm.value.is_accessible;
    this._authenticationService.update_user_permission_talent_pool(id, is_accessible).subscribe(
      (res) => {
        // this.toastr.success("Update user permission talent pool success");
        this.modalService.dismissAll();
        this.UpdateUserTalentPoolForm.reset();
        this.listUser('', null);
      },
      (err) => {
        this.toastr.error(err);
        this.UpdateUserTalentPoolForm.reset();
        this.modalService.dismissAll();
      }
    )
  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }
  public edit_picklist = false
  id_edit_picklist: any
  modalOpenMappingPicklist(modalBasicMappingPicklist: any, info: any = null) {
    if(info){
      this.createMappingPicklistForm = this.fb.group({
        hcm_system_eng_label: ["", Validators.required],
        hcm_system_label_id: ["", Validators.required],
        // company: ["", Validators.required],
        hcm_system_vn_label: ["", Validators.required],
      })
      this.edit_picklist = true
      this.createMappingPicklistForm.get("hcm_system_eng_label").setValue(info.hcm_system_eng_label)
      this.createMappingPicklistForm.get("hcm_system_label_id").setValue(info.hcm_system_label_id)
      this.createMappingPicklistForm.get("hcm_system_vn_label").setValue(info.hcm_system_vn_label)
      this.id_edit_picklist = info.id
    }else{
      this.edit_picklist = false
      this.createMappingPicklistForm = new FormGroup({
        type: new FormControl('', Validators.required),
        chr_label: new FormControl(null, Validators.required),
        hcm_system_label_id: new FormControl('', Validators.required),
        hcm_system_eng_label: new FormControl('', Validators.required),
        hcm_system_vn_label: new FormControl('', Validators.required),
  
      })
      this.createMappingPicklistForm.reset()
    }
    this.modalService.open(modalBasicMappingPicklist, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
    });
  }
  changeTypeEvent(){
    this.selectedType = this.createMappingPicklistForm.value.type
    this.getPicklist()
  }
  getListMappingPicklist() {
    this.isLoading = true;
    this._accountSettingsService.getMappingPicklist().subscribe((
      res
    ) => {
      this.listPicklist = res
      this.isLoading = false;
    },
      (err) => {
        console.log("ERROR", err)
        this.isLoading = false;
      })
  }
  getPicklist(){
    this.resumesService.get_CHR_picklist(this.selectedType).subscribe((res)=>{
      this.chr_label_items = res
    })
  }
  getBindLabel(): string {
    if(this.language=='en'){
      return 'label';
    }else if(this.language=='vn'){
      return 'vn_label';
    }
}
  createMappingPicklist() {
    this.submitted = true;
    if (this.createMappingPicklistForm.invalid) {
      return;
    }
    let formData: any = {}
    formData["chr_label"] = this.createMappingPicklistForm.value.chr_label
    formData["hcm_system_label_id"] = this.createMappingPicklistForm.value.hcm_system_label_id
    formData["hcm_system_eng_label"] = this.createMappingPicklistForm.value.hcm_system_eng_label
    formData["hcm_system_vn_label"] = this.createMappingPicklistForm.value.hcm_system_vn_label

    if(!this.edit_picklist){
      this._accountSettingsService.createMappingPicklist(formData).subscribe(
        (res) => {
          this.createMappingPicklistForm.reset();
          this.modalService.dismissAll();
          this.getListMappingPicklist();
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      )
    }else{
      this._accountSettingsService.updateMappingPicklist(this.id_edit_picklist, formData).subscribe(
        (res) => {
          this.createMappingPicklistForm.reset();
          this.modalService.dismissAll();
          this.getListMappingPicklist();
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      )
    }
    
  }
  deleteMappingPicklist(id: any){
    Swal.fire({
      title: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.DELETE_M"),
      text: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this._accountSettingsService.deleteMappingPicklist(id).subscribe(
          (res) => {
            this.toastr.success(
              this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.MODAL.DELETE_M"),
              this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.getListMappingPicklist();
          },
          (err) => {
            this.toastr.error(
              this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.ERROR"),
              this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  get f_createMappingPicklist() {
    return this.createMappingPicklistForm.controls;
  }
  /**
   * On init
   */
  ngOnInit() {
    if(window.location.href.includes("tab-overview")){
      this.selectedTab = 'tab-overview'
    }else if(window.location.href.includes("tab-change-pw")){
      this.selectedTab = 'tab-change-pw'
    }else if(window.location.href.includes("tab-users")){
      this.selectedTab = 'tab-users'
    }else if(window.location.href.includes("tab-auto-settings")){
      this.selectedTab = 'tab-auto-settings'
    }else if(window.location.href.includes("tab-connect-sf4c")){
      this.selectedTab = 'tab-connect-sf4c'
    }else if(window.location.href.includes("tab-source-mapping")){
      this.selectedTab = 'tab-source-mapping'
    }else if(window.location.href.includes("tab-mapping-picklist")){
      this.selectedTab = 'tab-mapping-picklist'
    }
    this.currentUser = this._authenticationService.currentUserValue;
    if (this.currentUser.is_superuser) {
      this.createUserForm = this.fb.group({
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        company: [null],
        sub_company: [null, Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", Validators.required],
        is_site_admin: false,
        id: [""],
        is_active: false
      })
      this.getListCompany()
      this.listUser('', null);
      this.getListSubCompany()
      // this.getListCredential()
    }
    this.editUser()
    if (!this.currentUser.is_superuser) {
      this.getDetailCredential()
    }
    if (this.currentUser.role == 'Admin' || this.currentUser.role == 'Super admin') {
      this.createUserForm = this.fb.group({
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        sub_company: [null, Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", Validators.required],
        is_site_admin: false,
        id: [""],
        is_active: false
      })
      this.getListCredential()
      this.GetSourceMapping()
      this.resumeSettings();
      this.getListMappingPicklist();
      this.listUser('', null);
      this.getListSubCompany()
    }

    // content header
    this.contentHeader = {
      headerTitle: 'Account Settings',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },

          {
            name: 'Account Settings',
            isLink: false
          }
        ]
      }
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
