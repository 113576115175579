import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Jr } from 'app/main/resume-parser/models/resume.model';
import { DetailResume, SearchResume, status_color } from '../../models/resume.model';
import { CV_task } from '../../models/job.model';
import { User } from 'app/auth/models';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TalentPoolService } from '../../services/talent-pool.service';
import { AuthenticationService } from 'app/auth/service';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
@Component({
  selector: 'app-detail-resume',
  templateUrl: './detail-resume.component.html',
  styleUrls: ['./detail-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailResumeComponent implements OnInit {
  public jrDetail!: Jr
  public src_link: any;
  public detail_resume: DetailResume = new DetailResume()
  public errorMessagesDetailResume!: any
  public errorMessagesListAllJd!: any
  public errorMessagesSaveResumeByJd!: any
  public list_all_jd:CV_task[]=[]

  // public candidate_detail: Cv = new Cv();
  public page = 1;
  public basicSelectedOption: number = 1;
  public pageBasicText = 1;
  public errorMessagesGetCv!:any
  public cv_html_src: string=""
  public saved_cv = false
  public job_description!:any
  public user: User = new User()
  @ViewChild('CV') CV!: ElementRef;
 
  public arr_checkboxSaveCv:any
  public arr_checkboxSaveCvJr:string[]=[]
  
  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCv: this.fb.array([],Validators.required),
    checkResumeArraySaveCvJr: this.fb.array([],Validators.required)

  })
  public save_cv: string = ''
  public pageSize=5
  public page_sf4c=1
  public pageSize_sf4c=8
  public loading = false
  public status_color=status_color
  public searchText: string
  totalResume: number
  currentResumeIndex: number
  candidateList: SearchResume[] = []
  candidateId: any
  public jd_id: string
  contentHeader: ContentHeader;
  language: string
  currentHref: string
  public errorDetailResume: any
  public listShared: any[] = []
  checkShared = false
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private talentPoolService: TalentPoolService,
    private resumeService: ResumeParserService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
  }

  DetailUser(){
    this._authenticationService.detailUser(this.detail_resume.owner).subscribe(
      (res)=>{
        this.user = res
      },
      (err)=>{
        console.error("Error: ",+err)
      }
    )
  }
  DetailResumeSearch(){
    this.loading = true
    let resume_id= this.route.snapshot.params["id"];
    this.talentPoolService.detail_resume(resume_id).subscribe(
      (res)=>{
        this.detail_resume = res;
        this.listSharedUser()
        // this.DetailUser();
        // const blob = this.base64ToBlob( this.detail_resume.pdf_content, 'application/pdf' );
        // this.src_link = URL.createObjectURL( blob );
        // this.loading = false
      },
      (err)=>{
        this.errorDetailResume = err
        err.error.detail?this.toastr.error(this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")):this.toastr.error(err.error.message)
      }
    )
  }
  base64ToBlob( base64: any, type = "application/octet-stream" ) {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params)=>{
      this.user = this._authenticationService.currentUserValue
      this.currentHref = window.location.href
      this.candidateId = this.currentHref.split("detail-candidate/")[1]
      this.jd_id = this.currentHref.includes("recommend-candidate")?this.currentHref.split("manage-job/")[1].split('/recommend-candidate')[0]:
      this.currentHref.includes("saved-candidate")?this.currentHref.split("manage-job/")[1].split('/saved-candidate')[0]:this.currentHref.includes("search-candidate")
      ?null:this.currentHref.split("manage-job/")[1].split('/detail-candidate')[0]
      this.DetailResumeSearch()
      // this.talentPoolService.idJd.subscribe((e)=>{
      //   this.jd_id = e
      // })
      this._coreConfigService.getConfig().subscribe((res) => {
        this.language = res.app.appLanguage;
        this.contentHeader = {
          headerTitle: this.language == 'vn' ? "Thông tin ứng viên" : "Candidate information",
          actionButton: false,
          breadcrumb: {
            type: "",
            links: [
              {
                name: this.language == 'vn' ? "Trang trước" : "Previous page",
                isLink: true,
                link: this.currentHref.includes("recommend-candidate")?`/manage-job/recommend-candidate/${this.jd_id}`:
                this.currentHref.includes("saved-candidate")?`/manage-job/saved-cvs/${this.jd_id}`:this.currentHref.includes("search-candidate")
                ?"/search-candidate":`/manage-job/detail-job/${this.jd_id}/tab-candidates`
              },
              {
                name: this.language == 'vn' ? "Chi tiết" : "Detail",
                isLink: false,
              },
            ],
          },
        };
      });
      
      this.job_description = this.currentHref.split("jd_id=")[1]
      this.talentPoolService.listCandidate.subscribe((res)=>{
        this.candidateList = res;
        this.totalResume = res.length
          this.candidateList.every((resume: any, index: number) => {
            if (resume.id == this.candidateId) {
              this.currentResumeIndex = index;
              return false;
            }
            return true;
          });
      })
    })
    
  }
  //save cv
  modalOpenSaveCv(modalBasicSaveCv:any) {
    this.ListAllJd()
    this.modalService.open(modalBasicSaveCv, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
    ListAllJd(){
      let owner = this.user.id
      let url_dynamic = '?owner=' + owner
      this.talentPoolService.list_all_jd(url_dynamic).subscribe(
        (res) => {
          this.list_all_jd = res;
          this.talentPoolService.setNewListJd(res)

        },
        (err) => {
          this.errorMessagesListAllJd = err
        }
      )
    }
    cancel(){
      this.searchText=''
      this.arr_checkboxSaveCv = []
    }
    Search(){
      // alert(this.searchText)
      let resultJr_cp :any
      this.talentPoolService.listJd.subscribe(
        (res)=>{
          resultJr_cp = res
        }
      )
      if(this.searchText!== ""){
        let searchValue = this.searchText.toLocaleLowerCase();
        this.list_all_jd = resultJr_cp.filter((resultJr_:any) =>{
          return resultJr_.job_title.toLocaleLowerCase().match(searchValue )
          ;
        // you can keep on adding object properties here
              });   
            }  
            else{
              this.list_all_jd = resultJr_cp
            }
          }
  onCheckboxChangeSaveCv(e:any) {
    let checkResumeArraySaveCv: any = this.formCheckBoxSaveCv.get('checkResumeArraySaveCv');
    if (e.target.checked) {
      checkResumeArraySaveCv = new FormControl(e.target.value);
      this.arr_checkboxSaveCv = e.target.value
    } else {
      checkResumeArraySaveCv = new FormControl()
      this.arr_checkboxSaveCv = null
    }
  }
  onCheckboxChangeSaveCvJr(e:any) {
    const checkResumeArraySaveCvJr: FormArray = this.formCheckBoxSaveCv.get('checkResumeArraySaveCvJr') as FormArray;
    if (e.target.checked) {
      checkResumeArraySaveCvJr.push(new FormControl(e.target.value));
      this.arr_checkboxSaveCvJr.push(e.target.value)
    } else {
      let i: number = 0;
      checkResumeArraySaveCvJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArraySaveCvJr.removeAt(i);
          this.arr_checkboxSaveCvJr=this.arr_checkboxSaveCvJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBoxSaveCv(resume: number){
    
    let job_description= parseInt(this.arr_checkboxSaveCv)
    this.talentPoolService.save_resume_by_jd(job_description, [resume]).subscribe(
      (res)=>{
        this.arr_checkboxSaveCv = null
        this.searchText= ''
        // this.toastr.success('Save Success')
      },
      (err)=>{
        this.arr_checkboxSaveCv = null
        this.searchText= ''
        err.error.detail?this.toastr.error(
          this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")
        ):this.toastr.error(err.error.message)
        this.errorMessagesSaveResumeByJd = err
      }
    )
  }
  //Save recommend detail cv
  SaveResumeByJd(){
    let job_description= Number(this.job_description)
    let candidate= this.detail_resume.id
    this.talentPoolService.save_resume_by_jd(job_description,candidate).subscribe(
      (res)=>{
        // this.toastr.success('Save Success');
        this.saved_cv = true;
      },
      (err)=>{
        this.errorMessagesSaveResumeByJd = err
      }
    )
  }
  swapLink(index: number) {
    if(this.currentHref.includes("recommend-candidate")){
      if (index < this.totalResume && index >= 0) {
        this.router.navigate([`/search-candidate/recommend-candidate/detail-candidate/${this.candidateList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnRecommendCandidate()
    }else if(this.currentHref.includes("saved-cvs")){
      if (index < this.totalResume && index >= 0) {
        this.router.navigate([`/search-candidate/saved-candidate/detail-candidate/${this.candidateList[index].id}`]);
        this.currentResumeIndex = index;
      } else this.returnSavedCandidate();
    }
    if (index < this.totalResume && index >= 0) {
      this.router.navigate([`/search-candidate/detail-candidate/${this.candidateList[index].id}`]);
      this.currentResumeIndex = index;
    } else this.returnSearchCandidate();
  }
  returnSearchCandidate(){
    this.router.navigate([`/search-candidate`]);
  }
  returnRecommendCandidate(){
    this.router.navigate([`/manage-job/recommend-candidate/${this.jd_id}`])
  }
  returnSavedCandidate(){
    this.router.navigate([`/manage-job/saved-cvs/${this.jd_id}`])
  }
  requestToShare(){
    var formData: any = {}
    formData["owner"] = this.detail_resume?.owner
    this.talentPoolService.requestToShare(this.detail_resume?.resume?.id, formData).subscribe(
      (res)=>{
        this.DetailResumeSearch()
      },
      (err)=>{
        this.toastr.error(err.error.message)
      }
    )
  }
  listSharedUser() {
    this.resumeService.listSharedUser(this.detail_resume?.resume?.id).subscribe((res) => {
      this.listShared = res.results
      this.listShared.forEach((e)=>{
        if(e.share_to==this.user.email){
          this.checkShared = true
        }
      })
    },
      (err) => {
        console.log("err", err)
      }
    )
  }
  toDetailResume(id_resume: any){
    let job_description = Number(this.jd_id);
    let resume = id_resume
    this.talentPoolService.seen_resumes(job_description, resume).subscribe((res)=>{
      window.open(`/manage-resume/detail-resume/${id_resume}`)
    },
    (err)=>{
      window.open(`/manage-resume/detail-resume/${id_resume}`)
    });
    
  }

}
