<div class="content-wrapper container-xxxl p-0">
    <div class="content-body">
        <div class="d-flex" id="h-CV">
            <div class="col-md-6">
                <div class="position-fixed" id="h-left" style="width: 38%;overflow-y: auto;overflow-x: hidden;">
                    <iframe [cachedSrc]="url"
                        style="width: 100%;height: 75vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <div class="" style="padding-top: 10px;">
                        <div class="">
                            <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT_CANDIDATE'"></h1>
                            <div>
                                <button (click)="onSubmit()" class="btn btn-primary rounded-pill btn-sm" rippleEffect
                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT'"></button>
                                <button (click)=cancel() rippleEffect class="btn btn-secondary rounded-pill ml-1 btn-sm"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
                            </div>
                        </div>
                        <form [formGroup]="EditResumeForm">

                            <div id="accordionExample" class="mt-1"
                                style="height: calc(100vh - 220px);overflow-y: scroll">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button type="button" class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">

                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="first_name"><span
                                                        class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="first_name" type="text" class="form-control"
                                                        id="first_name" name="first_name" required [class.error]="EditResumeForm.get('first_name')?.errors?.required"/>
                                                    <div
                                                        *ngIf="EditResumeForm.get('first_name')?.invalid && (EditResumeForm.get('first_name')?.dirty || EditResumeForm.get('first_name')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('first_name')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="last_name"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="last_name" type="text" class="form-control"
                                                        id="last_name" name="last_name" required [class.error]="EditResumeForm.get('last_name')?.errors?.required"/>
                                                    <div
                                                        *ngIf="EditResumeForm.get('last_name')?.invalid && (EditResumeForm.get('last_name')?.dirty || EditResumeForm.get('last_name')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('last_name')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="date_of_birth"><span
                                                        class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="date_of_birth" bsDatepicker type="text"
                                                        class="form-control" id="date_of_birth" name="date_of_birth"
                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                        autocomplete="off" />

                                                </div>

                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="gender"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <select id="gender" class="form-control"
                                                        (change)="changeGender($event)" formControlName="gender">

                                                        <option *ngFor="let genderItem of genderItems"
                                                            [value]="genderItem">
                                                            <span *ngIf="genderItem=='Female'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GENDER_.FEMALE'"></span><span
                                                                *ngIf="genderItem=='Male'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GENDER_.MALE'"></span>

                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="address"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="address" type="text" class="form-control"
                                                        id="address" name="address" />
                                                </div>

                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="email"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="email" type="email" class="form-control"
                                                        id="email_edit" name="email" required [class.error]="EditResumeForm.get('email')?.errors?.required"/>
                                                    <div
                                                        *ngIf="EditResumeForm.get('email')?.invalid && (EditResumeForm.get('email')?.dirty || EditResumeForm.get('email')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('email')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>

                                                    <small class="form-text text-danger" *ngIf="email.errors?.email"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.EMAIL_PATTERN'">
                                                        Email không đúng định dạng.
                                                    </small>

                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="phone_number"><span
                                                        class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <!-- <input formControlName="phone_number" type="text" phoneMask
                                                            class="form-control" id="phone_number_edit"
                                                            name="phone_number" /> -->
                                                    <input formControlName="phone_number" type="text"
                                                        class="form-control" id="phone_number_edit" name="phone_number"
                                                        required [class.error]="EditResumeForm.get('phone_number')?.errors?.required"/>
                                                    <div
                                                        *ngIf="EditResumeForm.get('phone_number')?.invalid && (EditResumeForm.get('phone_number')?.dirty || EditResumeForm.get('phone_number')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('phone_number')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>
                                                    <!-- <small class="form-text text-danger"
                                                            *ngIf="phone_number.errors?.pattern"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.PHONE_NUMBER_PATTERN'">
                                                            Số điện thoại phải có 10 đến 11 chữ số.
                                                        </small> -->
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="category"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <select id="category" class="form-control"
                                                        (change)="changeCategory($event)" formControlName="category">
                                                        <option value=""
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_CATEGORY'">
                                                        </option>
                                                        <option *ngFor="let categoryItem of categoryItems"
                                                            [value]="categoryItem">
                                                            <span>{{categoryItem}}</span>
                                                            <!-- <span *ngIf="categoryItem=='Sản xuất' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.PRODUCTION'"></span>
                                                                <span *ngIf="categoryItem=='Kỹ thuật' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.ENGINEERING'"></span>
                                                                <span *ngIf="categoryItem=='Kế toán / Tài chính' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.ACCOUNT'"></span>
                                                                <span *ngIf="categoryItem=='Xây dựng' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.CONSTRUCTION'"></span>
                                                                <span *ngIf="categoryItem=='Dịch vụ' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.SERVICES'"></span>
                                                                <span *ngIf="categoryItem=='Hành chính / Nhân sự' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.ADMINISTRATION'"></span>
                                                                <span *ngIf="categoryItem=='Bán hàng / Tiếp thị' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.SALES'"></span>
                                                                <span *ngIf="categoryItem=='Máy tính / Công nghệ thông tin' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.COMPUTER'"></span>
                                                                <span *ngIf="categoryItem=='Truyền thông / Media' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.MEDIA'"></span>
                                                                <span *ngIf="categoryItem=='Khách sạn / Du lịch' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.HOSPITALITY'"></span>
                                                                <span *ngIf="categoryItem=='Khoa học' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.SCIENCE'"></span>
                                                                <span *ngIf="categoryItem=='Nhóm ngành khác' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.INDUSTRIES'"></span>
                                                                <span *ngIf="categoryItem=='Chăm sóc sức khỏe' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.HEALTHCARE'"></span>
                                                                <span *ngIf="categoryItem=='Giáo dục / Đào tạo' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.EDUCATION'"></span>
                                                                <span *ngIf="categoryItem=='Hàng tiêu dùng' && categoryItem!='UNKNOWN'"[translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY_.CONSUMER'"></span> -->

                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <!-- <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="year_exp"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXPERIENCE'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <select id="year_exp" class="form-control"
                                                        (change)="changeExperienceYear($event)"
                                                        formControlName="year_exp" id="year_exp">
                                                        <option value=""
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_YEAR_EXPERIENCE'">
                                                        </option>
                                                        <option *ngFor="let expItem of expItems" [value]="expItem">
                                                            <span *ngIf="expItem=='Under 1 year' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.UNDER_YEAR'"></span>
                                                            <span *ngIf="expItem=='1 - 3 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.THREE_YEAR'"></span>
                                                            <span *ngIf="expItem=='3 - 5 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.FIVE_YEAR'"></span>
                                                            <span *ngIf="expItem=='5 - 10 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.TEN_YEAR'"></span>
                                                            <span *ngIf="expItem=='Over 10 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.OVER_TEN_YEAR'"></span>

                                                        </option>
                                                    </select>

                                                </div>
                                            </div> -->


                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button type="button" class="btn btn-link collapsed" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body" formArrayName="experience">
                                            <div class="d-flex justify-content-end">
                                                <button type="button" (click)="AddExperience()"
                                                    class="btn rounded-pill btn-outline-primary" rippleEffect><i
                                                        class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div *ngFor="let experience_ of experience.controls; let i = index">
                                                <div class="d-flex justify-content-between align-items-center mb-1">
                                                    <div class=""><span class="" style="width:fit-content"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.EXPERIENCE'"></span><span>
                                                            {{i+1}}</span></div>
                                                    <div class="mt-1 mt-lg-1">
                                                        <button type="button" (click)="DeleteExperience(i)"
                                                            class="btn rounded-pill btn-outline-danger" rippleEffect><i
                                                                class="fa fa-minus" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                                <div [formGroupName]="i">

                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input id="start_time" formControlName="start_time"
                                                                autocomplete="off" bsDatepicker type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                                                        </div>

                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input id="end_time" formControlName="end_time" bsDatepicker
                                                                type="text" class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                                autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span
                                                                style="color:red">*</span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea id="position" formControlName="position"
                                                                type="text" class="form-control"
                                                                style="width: fit-contend;height: fit-content"
                                                                required [class.error]="experience_.get('position')?.errors?.required"></textarea>
                                                            <div
                                                                *ngIf="experience_.get('position')?.invalid && (experience_.get('position')?.dirty || experience_.get('position')?.touched)">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="experience_.get('position')?.errors?.required"
                                                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                                    Công ty là bắt buộc.
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.INDUSTRY'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <select id="industry" class="form-control"
                                                                (change)="changeIndustry($event)"
                                                                formControlName="industry">
                                                                <option value=""
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_INDUSTRY'">
                                                                </option>
                                                                <option *ngFor="let industryItem of industryItems"
                                                                    [value]="industryItem">
                                                                    {{ industryItem }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea id="company" formControlName="company"
                                                                style="width: fit-contend;height: fit-content"
                                                                type="text" class="form-control"></textarea>
                                                            <!-- <div
                                                                    *ngIf="experience_.get('company')?.invalid && (experience_.get('company')?.dirty || experience_.get('company')?.touched)">
                                                                    <small class="form-text text-danger"
                                                                        *ngIf="experience_.get('company')?.errors?.required"
                                                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                                        Công ty là bắt buộc.
                                                                    </small>
                                                                </div> -->

                                                        </div>
                                                    </div>
                                                    <!-- <div class="form-group d-flex align-items-center">
                                                                <label class="col-sm-3"><span class="font-weight-bolder">Industry: </span></label>
                                                                <div class="col-sm-9">
                                                                    <select
                                                                        id="industry"
                                                                        class="form-control"
                                                                        (change)="changeIndustry($event)"
                                                                        formControlName="industry"
                                                                        
                                                                    >
                                                                        <option value="">Choose Industry</option>
                                                                        <option *ngFor="let industryItem of industryItems" [value]="industryItem">
                                                                        {{ industryItem }}
                                                                        </option>
                                                                    </select>
                                                                    
                                                                </div>
                                                            </div> -->

                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DETAIL'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea id="detail" formControlName="detail" rows="5"
                                                                type="text" class="form-control"></textarea>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button type="button" class="btn btn-link collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body" formArrayName="education">
                                            <div class="d-flex justify-content-end">
                                                <button type="button" (click)="AddEducation()"
                                                    class="btn rounded-pill btn-outline-primary" rippleEffect><i
                                                        class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>

                                            <div *ngFor="let education_ of education.controls; let i = index">
                                                <div class="d-flex justify-content-between align-items-center mb-1">
                                                    <div class=""><span style="width:fit-content"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION_'"></span><span>
                                                            {{i+1}}</span></div>
                                                    <div class="mt-1 mt-lg-1">
                                                        <button type="button" (click)="DeleteEducation(i)"
                                                            class="btn rounded-pill btn-outline-danger" rippleEffect><i
                                                                class="fa fa-minus" aria-hidden="true"></i></button>


                                                    </div>
                                                </div>

                                                <div [formGroupName]="i">
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input autocomplete="off" id="start_time"
                                                                formControlName="start_time" bsDatepicker type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input autocomplete="off" id="end_time"
                                                                formControlName="end_time" bsDatepicker type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center"
                                                        *ngIf="company!='4';else th_company">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea style="width: fit-contend;height: fit-content"
                                                                id="major" formControlName="major" type="text"
                                                                class="form-control"></textarea>
                                                        </div>
                                                    </div>
                                                    <ng-template #th_company>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder"
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>:
                                                                </span></label>
                                                            <div class="col-sm-9">
                                                                <textarea style="width: fit-contend;height: fit-content"
                                                                    id="major" formControlName="major" type="text"
                                                                    class="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder"
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span><span>:
                                                                </span></label>
                                                            <div class="col-sm-9">
                                                                <select id="picklist_major" class="form-control"
                                                                    (change)="changeMajor($event)"
                                                                    formControlName="picklist_major">
                                                                    <option value=""
                                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_MAJOR'">
                                                                    </option>
                                                                    <option *ngFor="let majorItem of majorItems"
                                                                        [value]="majorItem">
                                                                        {{ majorItem }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea style="width: fit-contend;height: fit-content"
                                                                id="school" formControlName="school" type="text"
                                                                class="form-control"></textarea>
                                                            <!-- <div
                                                                    *ngIf="education_.get('school')?.invalid && (education_.get('school')?.dirty || education_.get('school')?.touched)">
                                                                    <small class="form-text text-danger"
                                                                        *ngIf="education_.get('school')?.errors?.required"
                                                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                                        Trường là bắt buộc.
                                                                    </small>
                                                                </div> -->

                                                        </div>

                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DIPLOMA'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <select id="diploma" class="form-control"
                                                                (change)="changeDiploma($event)"
                                                                formControlName="diploma">
                                                                <option value=""
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_DIPLOMA'">
                                                                </option>
                                                                <option *ngFor="let diplomaItem of diplomaItems"
                                                                    [value]="diplomaItem">
                                                                    {{ diplomaItem }}
                                                                </option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input id="gpa" formControlName="gpa" type="text"
                                                                class="form-control" />
                                                        </div>
                                                    </div>
                                                    <!-- <div class="form-group d-flex align-items-center">
                                                                <label class="col-sm-3"><span class="font-weight-bolder">Picklist major: </span></label>
                                                                <div class="col-sm-9">
                                                                    <input
                                                                    
                                                                    id="picklist_major"
                                                                    formControlName="picklist_major"
                                                                    type="text"
                                                                    class="form-control"
                                                                    
                                                                />
                                                                    
                                                                    
                                                                </div>
                                                            </div> -->
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2">
                                <p *ngIf="errorMessagesEditResume!=null">Error: {{errorMessagesEditResume}}</p>
                            </div>
                            <!-- <div *ngIf="tag_transfer">
                                        <ngb-alert [type]="'success'" [dismissible]="false" class="mt-2">
                                            <div class="alert-body">
                                                <p>Update successfully! <a role="button" class="pxp-modal-link" routerLink="/employer/resume-detail/{{resume_parser.id}}">Preview</a></p>
                                            </div>
                                        </ngb-alert>
                                    </div> -->
                        </form>


                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="content-wrapper container-xxxl p-0">
    <div class="content-body">
        <div>
            <div class="col-md-6 position-fixed" id="h-left" style="width: 38%;overflow-y: auto;overflow-x: hidden;"
                *ngIf="url">

                
                <iframe [cachedSrc]="url"
                    style="width: 100%;height: 75vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
            </div>

            <div class="d-flex justify-content-end">
                <div style="margin-top: 30px;" *ngIf="errorMessagesEditResume">Error: <b
                        style="color: red;">{{errorMessagesEditResume}}</b></div>

                <div class="col-md-6">

                    <div class="" style="padding-top: 10px;">
                        <div  style="margin-top: -36px">
                            <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT_CANDIDATE'"></h1>
                            <div class="position-fixed" style="z-index: 99;">
                                <button (click)="onSubmit()" [disabled]="!EditResumeForm.valid"
                                    class="btn btn-primary rounded-pill" rippleEffect
                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT'"></button>
                                <button (click)=cancel() rippleEffect class="btn btn-secondary rounded-pill ml-1"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>

                            </div>
                        </div>
                        <form [formGroup]="EditResumeForm">

                            <div id="accordionExample" class="mt-5">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button type="button" class="btn btn-link" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">

                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="first_name"><span
                                                        class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="first_name" type="text" class="form-control"
                                                        id="first_name" name="first_name" required />
                                                    <div
                                                        *ngIf="EditResumeForm.get('first_name')?.invalid && (EditResumeForm.get('first_name')?.dirty || EditResumeForm.get('first_name')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('first_name')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="last_name"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="last_name" type="text" class="form-control"
                                                        id="last_name" name="last_name" required />
                                                    <div
                                                        *ngIf="EditResumeForm.get('last_name')?.invalid && (EditResumeForm.get('last_name')?.dirty || EditResumeForm.get('last_name')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('last_name')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="date_of_birth"><span
                                                        class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="date_of_birth" bsDatepicker type="text"
                                                        class="form-control" id="date_of_birth" name="date_of_birth"
                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                        autocomplete="off" />

                                                </div>

                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="gender"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <select id="gender" class="form-control"
                                                        (change)="changeGender($event)" formControlName="gender">

                                                        <option *ngFor="let genderItem of genderItems"
                                                            [value]="genderItem">
                                                            <span *ngIf="genderItem=='Female'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GENDER_.FEMALE'"></span><span
                                                                *ngIf="genderItem=='Male'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GENDER_.MALE'"></span>

                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="address"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="address" type="text" class="form-control"
                                                        id="address" name="address" />
                                                </div>

                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="email"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <input formControlName="email" type="email" class="form-control"
                                                        id="email_edit" name="email" required />
                                                    <div
                                                        *ngIf="EditResumeForm.get('email')?.invalid && (EditResumeForm.get('email')?.dirty || EditResumeForm.get('email')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('email')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>

                                                    <small class="form-text text-danger" *ngIf="email.errors?.email"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.EMAIL_PATTERN'">
                                                        Email không đúng định dạng.
                                                    </small>

                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="phone_number"><span
                                                        class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span><span
                                                        style="color:red">*</span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    
                                                    <input formControlName="phone_number" type="text"
                                                        class="form-control" id="phone_number_edit" name="phone_number"
                                                        required />
                                                    <div
                                                        *ngIf="EditResumeForm.get('phone_number')?.invalid && (EditResumeForm.get('phone_number')?.dirty || EditResumeForm.get('phone_number')?.touched)">
                                                        <small class="form-text text-danger"
                                                            *ngIf="EditResumeForm.get('phone_number')?.errors?.required"
                                                            [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                            Công ty là bắt buộc.
                                                        </small>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="category"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <select id="category" class="form-control"
                                                        (change)="changeCategory($event)" formControlName="category">
                                                        <option value=""
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_CATEGORY'">
                                                        </option>
                                                        <option *ngFor="let categoryItem of categoryItems"
                                                            [value]="categoryItem">
                                                            <span>{{categoryItem}}</span>
                                                            

                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="col-sm-3" for="year_exp"><span class="font-weight-bolder"
                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXPERIENCE'"></span><span>:
                                                    </span></label>
                                                <div class="col-sm-9">
                                                    <select id="year_exp" class="form-control"
                                                        (change)="changeExperienceYear($event)"
                                                        formControlName="year_exp" id="year_exp">
                                                        <option value=""
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_YEAR_EXPERIENCE'">
                                                        </option>
                                                        <option *ngFor="let expItem of expItems" [value]="expItem">
                                                            <span *ngIf="expItem=='Under 1 year' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.UNDER_YEAR'"></span>
                                                            <span *ngIf="expItem=='1 - 3 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.THREE_YEAR'"></span>
                                                            <span *ngIf="expItem=='3 - 5 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.FIVE_YEAR'"></span>
                                                            <span *ngIf="expItem=='5 - 10 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.TEN_YEAR'"></span>
                                                            <span *ngIf="expItem=='Over 10 years' && expItem!='UNKNOWN'"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXP_.OVER_TEN_YEAR'"></span>

                                                        </option>
                                                    </select>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button type="button" class="btn btn-link collapsed" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body" formArrayName="experience">
                                            <div class="d-flex justify-content-end">
                                                <button type="button" (click)="AddExperience()"
                                                    class="btn rounded-pill btn-outline-primary" rippleEffect><i
                                                        class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div *ngFor="let experience_ of experience.controls; let i = index">
                                                <div class="d-flex justify-content-between align-items-center mb-1">
                                                    <div class=""><span class="" style="width:fit-content"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.EXPERIENCE'"></span><span>
                                                            {{i+1}}</span></div>
                                                    <div class="mt-1 mt-lg-1">
                                                        <button type="button" (click)="DeleteExperience(i)"
                                                            class="btn rounded-pill btn-outline-danger" rippleEffect><i
                                                                class="fa fa-minus" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                                <div [formGroupName]="i">

                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input id="start_time" formControlName="start_time"
                                                                autocomplete="off" bsDatepicker type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                                                        </div>

                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input id="end_time" formControlName="end_time" bsDatepicker
                                                                type="text" class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                                autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span
                                                                style="color:red">*</span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea id="position" formControlName="position"
                                                                type="text" class="form-control"
                                                                style="width: fit-contend;height: fit-content" required></textarea>
                                                                <div
                                                                *ngIf="experience_.get('position')?.invalid && (experience_.get('position')?.dirty || experience_.get('position')?.touched)">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="experience_.get('position')?.errors?.required"
                                                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">
                                                                    Công ty là bắt buộc.
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.INDUSTRY'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <select id="industry" class="form-control"
                                                                (change)="changeIndustry($event)"
                                                                formControlName="industry">
                                                                <option value=""
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_INDUSTRY'">
                                                                </option>
                                                                <option *ngFor="let industryItem of industryItems"
                                                                    [value]="industryItem">
                                                                    {{ industryItem }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea id="company" formControlName="company"
                                                                style="width: fit-contend;height: fit-content"
                                                                type="text" class="form-control"></textarea>
                                                            

                                                        </div>
                                                    </div>
                                                    

                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DETAIL'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea id="detail" formControlName="detail" rows="5"
                                                                type="text" class="form-control"></textarea>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button type="button" class="btn btn-link collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">

                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body" formArrayName="education">
                                            <div class="d-flex justify-content-end">
                                                <button type="button" (click)="AddEducation()"
                                                    class="btn rounded-pill btn-outline-primary" rippleEffect><i
                                                        class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>

                                            <div *ngFor="let education_ of education.controls; let i = index">
                                                <div class="d-flex justify-content-between align-items-center mb-1">
                                                    <div class=""><span style="width:fit-content"
                                                            [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION_'"></span><span>
                                                            {{i+1}}</span></div>
                                                    <div class="mt-1 mt-lg-1">
                                                        <button type="button" (click)="DeleteEducation(i)"
                                                            class="btn rounded-pill btn-outline-danger" rippleEffect><i
                                                                class="fa fa-minus" aria-hidden="true"></i></button>


                                                    </div>
                                                </div>

                                                <div [formGroupName]="i">
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input autocomplete="off" id="start_time"
                                                                formControlName="start_time" bsDatepicker type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input autocomplete="off" id="end_time"
                                                                formControlName="end_time" bsDatepicker type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center"
                                                        *ngIf="company!='4';else th_company">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea style="width: fit-contend;height: fit-content"
                                                                id="major" formControlName="major" type="text"
                                                                class="form-control"></textarea>
                                                        </div>
                                                    </div>
                                                    <ng-template #th_company>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder"
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>:
                                                                </span></label>
                                                            <div class="col-sm-9">
                                                                <textarea style="width: fit-contend;height: fit-content"
                                                                    id="major" formControlName="major" type="text"
                                                                    class="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder"
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span><span>:
                                                                </span></label>
                                                            <div class="col-sm-9">
                                                                <select id="picklist_major" class="form-control"
                                                                    (change)="changeMajor($event)"
                                                                    formControlName="picklist_major">
                                                                    <option value=""
                                                                        [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_MAJOR'">
                                                                    </option>
                                                                    <option *ngFor="let majorItem of majorItems"
                                                                        [value]="majorItem">
                                                                        {{ majorItem }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <textarea style="width: fit-contend;height: fit-content"
                                                                id="school" formControlName="school" type="text"
                                                                class="form-control"></textarea>
                                                            

                                                        </div>

                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.DIPLOMA'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <select id="diploma" class="form-control"
                                                                (change)="changeDiploma($event)"
                                                                formControlName="diploma">
                                                                <option value=""
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_DIPLOMA'">
                                                                </option>
                                                                <option *ngFor="let diplomaItem of diplomaItems"
                                                                    [value]="diplomaItem">
                                                                    {{ diplomaItem }}
                                                                </option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex align-items-center">
                                                        <label class="col-sm-3"><span class="font-weight-bolder"
                                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span><span>:
                                                            </span></label>
                                                        <div class="col-sm-9">
                                                            <input id="gpa" formControlName="gpa" type="text"
                                                                class="form-control" />
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-2">
                                <p *ngIf="errorMessagesEditResume!=null">Error: {{errorMessagesEditResume}}</p>
                            </div>
                            
                        </form>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div> -->