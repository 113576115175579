import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CHR_Picklist, ResumeParser } from '../../models/resume.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'app/auth/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ResumeParserService } from '../../services/resume-parser.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { CategoryItems } from 'app/main/talent-pool/models/job.model';
import { CoreConfigService } from '@core/services/config.service';
@Component({
  selector: 'app-edit-resume',
  templateUrl: './edit-resume.component.html',
  styleUrls: ['./edit-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditResumeComponent implements OnInit {
  public isAuthenticated=false
  public resume_parser!: ResumeParser
  public errorMessagesResumeParser!: any
  public errorMessagesApproveResume!: any
  public errorMessagesEditResume!: any
  public tag_status= false
  public tag_transfer = false
  public url!: any
  public chr_picklist: CHR_Picklist[] = []
  public errorMessagesChrPicklist!: any
  // public EditResumeForm!: FormGroup
  EditResumeForm = this.fb.group({
    first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      date_of_birth: new FormControl(),
      gender: new FormControl(''),
      address: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone_number: new FormControl('', Validators.required),
      // phone_number: new FormControl('',[
      //   Validators.pattern('[- +()0-9]{10}') // Số điện thoại từ 10 đến 11 chữ số ^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$
      // ]),
      category: new FormControl(''),
      year_exp: new FormControl(''),
    experience: new FormArray([]),
    education: new FormArray([])
  })
  
  public genderItems = ['Male','Female']
  public categoryItems:string[] = []
  public expItems = ['Under 1 year', '1 - 3 years','3 - 5 years','5 - 10 years','Over 10 years']
  public industryItems:string[] = []
  public diplomaItems:string[] = []
  public majorItems:string[] = []
  public picklist_majorItems = ['picklist_major 1', 'picklist_major 2']
  public user: User = new User()
  public company: string;
  language: string
  language_be: string
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdref: ChangeDetectorRef,
    private resumesService: ResumeParserService,
    private toastr: ToastrService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
    // this.EditResumeForm.get('phone_number')?.valueChanges.subscribe(value => {
    //   if (value) {
    //     const formattedValue = this.formatPhoneNumber(value);
    //     this.EditResumeForm.get('phone_number')?.setValue(formattedValue, { emitEvent: false });
    //   }
    // });
  }
  formatPhoneNumber(value: string): string {
    // Loại bỏ các ký tự không phải số
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }

    return value;
  }
  changeGender(e: any) {
    this.gender?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  changeCategory(e: any) {
    this.category?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  changeExperienceYear(e: any) {
    this.year_exp?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  changeIndustry(event: any) {
    this.industryItems.find((r) => r == event);
    // const creds = this.form.controls.countries as FormArray;
    // creds.at(1).get('country').setValue('USA');
    // let formArray = this.form.get('products') as FormArray;
  }
  changeDiploma(event: any) {
    this.diplomaItems.find((r) => r == event);
    // const creds = this.form.controls.countries as FormArray;
    // creds.at(1).get('country').setValue('USA');
    // let formArray = this.form.get('products') as FormArray;
  }
  changeMajor(event: any) {
    this.majorItems.find((r) => r == event);
  }
  changePicklistMajor(event: any) {
    this.picklist_majorItems.find((r) => r == event);
    // const creds = this.form.controls.countries as FormArray;
    // creds.at(1).get('country').setValue('USA');
    // let formArray = this.form.get('products') as FormArray;
  }
  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  // Access formcontrols getter
  get first_name() {
    return this.EditResumeForm.get('first_name');
  }
  get last_name() {
    return this.EditResumeForm.get('last_name');
  }
  get date_of_birth() {
    return this.EditResumeForm.get('date_of_birth');
  }
  get gender() {
    return this.EditResumeForm.get('gender');
  }
  get address() {
    return this.EditResumeForm.get('address');
  }
  get category(){
    return this.EditResumeForm.get('category')
  }
  get year_exp(){
    return this.EditResumeForm.get('year_exp')
  }
  get phone_number() {
    return this.EditResumeForm.get('phone_number');
  }
  get email() {
    return this.EditResumeForm.get('email');
  }
  
  get experience(): FormArray{
    return this.EditResumeForm.controls["experience"] as FormArray
  }
  get education(): FormArray{
    return this.EditResumeForm.controls["education"] as FormArray
  }

  onSubmit(){
    this.ChangeResume();
    
  }
  ChangeResume(){
    let _experience = []
    let _education = []
    if(this.EditResumeForm.value.experience.length>0){
      for (let i=0;i< this.EditResumeForm.value.experience.length;i++){
        
        if(this.EditResumeForm.value.experience[i].id>-1){
          
          _experience.push({"id":this.EditResumeForm.value.experience[i].id,
                            "start_time":(this.EditResumeForm.value.experience[i].start_time==undefined||'')?null:new Date(new Date(this.EditResumeForm.value.experience[i].start_time)).toISOString(),
                            "end_time": this.EditResumeForm.value.experience[i].end_time==undefined||''?null:new Date(new Date(this.EditResumeForm.value.experience[i].end_time)).toISOString(),
                            "company": this.EditResumeForm.value.experience[i].company,
                            "position": this.EditResumeForm.value.experience[i].position,
                            "detail": this.EditResumeForm.value.experience[i].detail,
                            "industry": this.EditResumeForm.value.experience[i].industry  
        })
        }else{
          _experience.push({
                            "start_time":this.EditResumeForm.value.experience[i].start_time==''?null:new Date(new Date(this.EditResumeForm.value.experience[i].start_time)).toISOString(),
                            "end_time": this.EditResumeForm.value.experience[i].end_time==''?null:new Date(new Date(this.EditResumeForm.value.experience[i].end_time)).toISOString(),
                            "company": this.EditResumeForm.value.experience[i].company,
                            "position": this.EditResumeForm.value.experience[i].position,
                            "detail": this.EditResumeForm.value.experience[i].detail,
                            "industry": this.EditResumeForm.value.experience[i].industry
        })
        }
  
      }
    }
    if(this.EditResumeForm.value.education.length>0){
      for (let i=0;i< this.EditResumeForm.value.education.length;i++){
        if(this.EditResumeForm.value.education[i].id>-1){
          _education.push({"id":this.EditResumeForm.value.education[i].id,
                            "start_time":this.EditResumeForm.value.education[i].start_time==undefined?null:new Date(new Date(this.EditResumeForm.value.education[i].start_time)).toISOString(),
                            "end_time": this.EditResumeForm.value.education[i].end_time==undefined?null:new Date(new Date(this.EditResumeForm.value.education[i].end_time)).toISOString(),
                            "major": this.EditResumeForm.value.education[i].major,
                            "school": this.EditResumeForm.value.education[i].school,
                            "diploma": this.EditResumeForm.value.education[i].diploma,
                            "gpa": this.EditResumeForm.value.education[i].gpa,
                            "picklist_major": this.EditResumeForm.value.education[i].picklist_major
        })
        }else{
          _education.push({
                            "start_time": this.EditResumeForm.value.education[i].start_time==''?null:new Date(new Date(this.EditResumeForm.value.education[i].start_time)).toISOString(),
                            "end_time": this.EditResumeForm.value.education[i].end_time==''?null:new Date(new Date(this.EditResumeForm.value.education[i].end_time)).toISOString(),
                            "major": this.EditResumeForm.value.education[i].major,
                            "school": this.EditResumeForm.value.education[i].school,
                            "diploma": this.EditResumeForm.value.education[i].diploma,
                            "gpa": this.EditResumeForm.value.education[i].gpa,
                            "picklist_major": this.EditResumeForm.value.education[i].picklist_major  

        })
        }
  
      }
    }
    const id= Number(this.route.snapshot.paramMap.get('id'))
    const first_name= this.EditResumeForm.value.first_name
    const last_name= this.EditResumeForm.value.last_name
    const date_of_birth= this.EditResumeForm.value.date_of_birth==undefined?null:new Date(this.EditResumeForm.value.date_of_birth)
    const gender= this.EditResumeForm.value.gender
    const address= this.EditResumeForm.value.address
    const email= this.EditResumeForm.value.email
    var phone_number= this.EditResumeForm.value.phone_number
    const category= this.EditResumeForm.value.category
    const year_exp= this.EditResumeForm.value.year_exp
    const experience= _experience
    const education= _education
    this.resumesService.updateResume(id,first_name, last_name, email, phone_number, gender, date_of_birth, address, category, year_exp, experience, education).subscribe(res=>{
      if(res){
        this.resume_parser = res
        // this.toastr.success(
        //   // "",
        //   // `Approved Success`,
        //   this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
        //   this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.EDIT_R_SUC"),
        //   {
        //     toastClass: "toast ngx-toastr",
        //     closeButton: true,
        //   }
        // );
        this._router.navigateByUrl("/manage-resume/detail-resume/"+this.resume_parser.id.toString())
      
      }
    }, (err)=>{
      if(this.language=='en'){
        this.toastr.warning(err.error.message.en.join(', '))
      }else{
        this.toastr.warning(err.error.message.vn.join(', '))
      }
    }
      )
  }
  
  CreateExperience(): FormGroup{
    return this.fb.group({
        start_time: new FormControl(''),
        end_time: new FormControl(''),
        position: new FormControl('', Validators.required),
        company: new FormControl(''),
        detail: new FormControl(''),
        industry: new FormControl('')
    })
  }
  CreateEducation(): FormGroup{
    return this.fb.group({
      start_time: new FormControl(''),
      end_time: new FormControl(''),
      major: new FormControl(''),
      school: new FormControl(''),
      diploma: new FormControl(''),
      gpa: new FormControl(''),
      picklist_major: new FormControl('')
    })
  }
  AddExperience(){
    this.experience.push(
      this.CreateExperience()
    )
  }
  public DeleteExperienceId:number[]=[]
  DeleteExperience(id: number){
    for(let i=0;i<this.experience.length;i++){
      if(i==id){
        this.DeleteExperienceId.push(this.experience.controls[i].value.id)
      } 
    }
    this.experience.removeAt(id);
  }
  AddEducation(){
    this.education.push(
      this.CreateEducation()
    )
  }
  public DeleteEducationId:number[]=[]

  DeleteEducation(id: number){
    for(let i=0;i<this.education.length;i++){
      if(i==id){
        this.DeleteEducationId.push(this.education.controls[i].value.id)
      } 
    }
    this.education.removeAt(id);
    
  }
  base64ToBlob( base64: any, type = "application/octet-stream" ) {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
  }
  
  GetDetailResume(){
    this.resumesService.getDetailResume(this.route.snapshot.params["id"]).subscribe(res=>{
      if(res.id!=null && res.id!=undefined){
        this.resume_parser = res
        this.language_be = res.language
        const blob = this.base64ToBlob( this.resume_parser.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
        this.EditResume()
      }
    })
  }

  get_chr_picklist(){
    let url_dynamic_diploma = "diploma"
    let url_dynamic_industry = "industry"
    let url_dynamic_major = "major"
    let url_dynamic_category = "category"


    this.resumesService.get_CHR_picklist(url_dynamic_diploma).subscribe(x=>{
      this.diplomaItems = x.map((e)=>this.language_be=='en'?e.label:e.vn_label)
      //this.diplomaItems.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    })
    this.resumesService.get_CHR_picklist(url_dynamic_industry).subscribe(x=>{
      this.industryItems = x.map((e)=>this.language_be=='en'?e.label:e.vn_label)
      //this.diplomaItems.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    })
    this.resumesService.get_CHR_picklist(url_dynamic_major).subscribe(x=>{
      this.majorItems = x.map((e)=>this.language_be=='en'?e.label:e.vn_label)
      //this.diplomaItems.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    })
    this.resumesService.get_CHR_picklist(url_dynamic_category).subscribe(x=>{
      this.categoryItems = x.map((e)=>this.language_be=='en'?e.label:e.vn_label)
      //this.diplomaItems.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    })
  }
  EditResume(){
    this.get_chr_picklist()
    for (let i = 0; i< this.resume_parser.experience.length; i++){

      this.experience.push(
        this.fb.group({
          id: this.resume_parser.experience[i].id,
          // range: [[this.resume_parser.experience[i].start_time==null?null:new Date(this.resume_parser.experience[i].start_time),this.resume_parser.experience[i].end_time==null?null:new Date(this.resume_parser.experience[i].end_time)]],
          start_time: this.resume_parser.experience[i].start_time==null?null:new Date(this.resume_parser.experience[i].start_time),
          end_time: this.resume_parser.experience[i].end_time==null?null:new Date(this.resume_parser.experience[i].end_time),
          position: this.resume_parser.experience[i].position,
          company: this.resume_parser.experience[i].company,
          detail: this.resume_parser.experience[i].detail,
          industry: this.resume_parser.experience[i].industry

        })
      )
    }
    for (let i = 0; i< this.resume_parser.education.length; i++){
      this.education.push(
        this.fb.group({
          id: this.resume_parser.education[i].id,
          // range: [[this.resume_parser.education[i].start_time==null?null:new Date(this.resume_parser.education[i].start_time),this.resume_parser.education[i].end_time==null?null:new Date(this.resume_parser.education[i].end_time)]],

          start_time: this.resume_parser.education[i].start_time==null?null:new Date(this.resume_parser.education[i].start_time),
          end_time: this.resume_parser.education[i].end_time==null?null:new Date(this.resume_parser.education[i].end_time),
          major: this.resume_parser.education[i].major,
          school: this.resume_parser.education[i].school,
          diploma: this.resume_parser.education[i].diploma,
          gpa: this.resume_parser.education[i].gpa,
          picklist_major: this.resume_parser.education[i].picklist_major

        })
      )
    }
    this.EditResumeForm.patchValue({
      first_name: this.resume_parser.first_name,
      last_name: this.resume_parser.last_name,
      date_of_birth: this.resume_parser.date_of_birth==undefined?null:new Date(this.resume_parser.date_of_birth),
      gender: this.resume_parser.gender,
      address: this.resume_parser.address,
      email: this.resume_parser.email,
      phone_number: this.resume_parser.phone_number,
      // phone_number: '0'+this.resume_parser.phone_number.trim().replace(/\D/g, '').slice(-9),
      category: this.resume_parser.category,
      year_exp: this.resume_parser.year_exp,
      experience: [this.experience],
      education: [this.education]
    })
  }
  cancel(){
    this._router.navigateByUrl("/manage-resume/detail-resume/"+this.resume_parser.id.toString())
  }
  ngOnInit(): void {
    this.company = localStorage.getItem("company");
    this.GetDetailResume();

  }

}
